import React from 'react';
import styled from 'styled-components';
import { Heading, Text } from 'components';
import { ICollection } from 'config/constants/types';
import { COLLECTION_BASE_URL } from 'views/Collection/types';

const WrapperLink = styled.a`
    display: block;
    border-radius: 16px;
    border: 1px solid ${ ( { theme } ) => theme.colors.cardBorderSubtle };
    overflow: hidden;
    
    &:hover {
        box-shadow: 0px 0px 15px -5px #BBB;
    }
`;

const BannerWrapper = styled.div`
    height: 10rem;
    width: 100%;
    position: relative;
    overflow: hidden;
    
    & > img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
`;

const LogoWrapper = styled.div`
    background-color: white;
    width: 60px;
    height: 60px;
    overflow: hidden;
    margin: -2.5rem auto 0;
    border: 3px solid white;
    box-shadow: rgb(14 14 14 / 60%) 0px 0px 2px 0px;
    position: relative;
    border-radius: 50%;
`;

const CollectionData = styled.div`
    padding: 0.75rem;
`;

interface CollectionProps {
    collection: ICollection
}

const Collection: React.FC<CollectionProps> = ( props ) => {
    const { collection } = props;

    const viewCollectionUrl = `${ COLLECTION_BASE_URL }/${ collection.slug }`;

    return (
        <WrapperLink href={ viewCollectionUrl }>
            <BannerWrapper>
                <img src={ collection.bannerImage }/>
            </BannerWrapper>

            <CollectionData>
                <LogoWrapper>
                    <img src={ collection.logoImage }/>
                </LogoWrapper>

                <Heading scale="sm" textAlign="center" my={ 3 }>
                    { collection.title }
                </Heading>

                <Text color="textSubtle" ellipsis small>
                    { collection.description }
                </Text>
            </CollectionData>
        </WrapperLink>
    );
};

export default Collection;