import React from 'react';
import styled from 'styled-components';
import { Heading, Skeleton, Text } from 'components';
import { variant as VARIANT } from 'components/Skeleton/types';

const WrapperLink = styled.div`
    border-radius: 16px;
    border: 1px solid ${ ( { theme } ) => theme.colors.backgroundDisabled };
    overflow: hidden;
`;

const BannerWrapper = styled.div`
    height: 10rem;
    width: 100%;
    position: relative;
    overflow: hidden;
`;

const LogoWrapper = styled.div`
    background-color: white;
    width: 60px;
    height: 60px;
    overflow: hidden;
    margin: -2.5rem auto 0;
    border: 3px solid white;
    position: relative;
    border-radius: 50%;
`;

const CollectionData = styled.div`
    padding: 0.75rem;
`;

const CollectionSkeleton: React.FC = () => {
    return (
        <WrapperLink>
            <BannerWrapper>
                <Skeleton height='100%' width='100%'/>
            </BannerWrapper>

            <CollectionData>
                <LogoWrapper>
                    <Skeleton variant={ VARIANT.CIRCLE } width='100%' height='100%'/>
                </LogoWrapper>

                <Heading scale="sm" textAlign="center" my={ 3 }>
                    <Skeleton height='20px' width='100%'/>
                </Heading>

                <Text color="textSubtle" ellipsis small>
                    <Skeleton height='20px' width='100%'/>
                </Text>
            </CollectionData>
        </WrapperLink>
    );
};

export default CollectionSkeleton;