import { useEffect, useState } from 'react';
import { FetchStatus, ICollection } from 'config/constants/types';
import useFetchFromApi from 'hooks/useFetchFromApi';

const useRandomCollections = ( category: string ) => {
    const { fetchDataFromApi } = useFetchFromApi();

    const [ collections, setCollections ] = useState<ICollection[]>( [] );
    const [ fetchStatus, setFetchStatus ] = useState<FetchStatus>( FetchStatus.PENDING );

    useEffect( () => {

        const fetchCollections = async () => {
            try {
                const response = await fetchDataFromApi( `/collections/random/${ category }` );

                setCollections( response as ICollection[] );
                setFetchStatus( FetchStatus.SUCCESS );
            } catch ( err ) {
                setFetchStatus( FetchStatus.FAILED );
                throw err;
            }
        };

        fetchCollections().catch( err => console.warn( 'Error fetching random collections:', err.message ) );
    }, [
        fetchDataFromApi,
        category
    ] );

    return {
        collections,
        fetchStatus
    };
};


export default useRandomCollections;