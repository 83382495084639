export enum ChainId {
    MAINNET = 3000,
    TESTNET = 3001,
}

export const DEFAULT_CHAIN_ID = ChainId.MAINNET;

export const RPC_URLS = [
    'https://rpc.ech.network'
];

export const EXPLORER_URLS = {
    [ChainId.MAINNET]: 'https://scout.ech.network',
    [ChainId.TESTNET]: '',
};