import { Token } from './types';

interface TokenList {
    [key: string]: Token
}

const tokens: TokenList = {
    ech: {
        name: 'Wrapped ECH',
        symbol: 'ECH',
        address: '0x1eEDFf2A89e58c5fC4650C2de45DDA0964C3D343',
        decimals: 18,
    },
    usdc: {
        name: 'Bridged USDC',
        symbol: 'USDC',
        address: '0xc42974d6554F9054265b477723C3f689d8699239',
        decimals: 6,
    },
};

export const getTokenByAddress = ( address: string ): Token | null => {
    const tokenKey = Object.keys( tokens )
                           .find( ( key: string ) => tokens[key].address.toLowerCase() === address.toLowerCase() );

    if ( tokenKey ) {
        return tokens[tokenKey];
    }

    return null;
};

export default tokens;