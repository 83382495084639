import React from 'react';
import styled from 'styled-components';
import { Button, Heading } from 'components';
import { Link } from 'react-router-dom';

const StyledNotFound = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  background-color: ${ ( { theme } ) => theme.colors.background };
`;

const NotFound = () => {
    return (
        <StyledNotFound>
            <Heading color="primary" scale="xl" mb={ 2 }>Page not found</Heading>
            <Button as={ Link } to="/" scale="sm" variant="secondary">
                Back Home
            </Button>
        </StyledNotFound>
    );
};

export default NotFound;
