import { GAS_PRICE_GWEI } from 'config/constants/gas';

/**
 * Function to return gasPrice outwith a react component
 */
const getGasPrice = (): string => {
    return GAS_PRICE_GWEI.default;
};

export default getGasPrice;
