import React from 'react';
import { EyeIcon, Flex, Skeleton, Text } from 'components';
import { FetchStatus, IAsset } from 'config/constants/types';
import { formatNumber } from 'utils/formatBalance';
import useFetchFromApi, { RequestMethod } from '../../../hooks/useFetchFromApi';

export interface AssetViewsProps {
    asset?: IAsset
    assetDataFetchStatus: FetchStatus
}

const AssetViews: React.FC<AssetViewsProps> = ( props ) => {
    const { asset, assetDataFetchStatus } = props;

    const { fetchDataFromApi } = useFetchFromApi();

    React.useEffect(
        () => {
            const incrementViews = async () => await fetchDataFromApi(
                `/asset/incrementViews/${ asset?.collection_contract }/${ asset?.asset_id }`,
                RequestMethod.PUT
            );

            if ( assetDataFetchStatus === FetchStatus.SUCCESS && asset?.collection_contract && asset?.asset_id ) {
                incrementViews().catch( err => console.error( err ) );
            }
        },
        [
            assetDataFetchStatus
        ]
    );

    return (
        <Flex>
            <EyeIcon color="textSubtle" mr={ 2 }/>
            { assetDataFetchStatus === FetchStatus.PENDING ? (
                <Skeleton/>
            ) : (
                <Text color="textSubtle">
                    { formatNumber( asset?.views ?? 0, 0, 0 ) } views
                </Text>
            ) }
        </Flex>
    );
};

export default AssetViews;