import { ChainId } from '../chain';

export interface IAddress {
    [ChainId.MAINNET]?: string
    [ChainId.TESTNET]: string
}

export enum FetchStatus {
    PENDING = 'pending',
    SUCCESS = 'success',
    FAILED = 'failed',
}

/**
 * COLLECTION
 */
export type ICollection = {
    title: string
    description?: string
    bannerImage?: string
    logoImage?: string
    creator: string
    symbol?: string // TODO: Needed?
    contract_address: string
    slug: string
    chain_id?: number // TODO: Check
    creatorFees?: number // TODO: Check

    websiteUrl?: string
    discordInvite?: string
    twitterUsername?: string

    stats?: ICollectionStats
}

export interface ICollectionStats {
    floorPrice: string
    numOwners: number
    totalSupply: number
    totalVolume: string
    bestCollectionOffer?: number
}

export type ICollectionAttribute = {
    label: string
    rarity: number
}
export type ICollectionAttributes = {
    [groupName: string]: ICollectionAttribute[]
}

/**
 * ASSET
 */
export type IAsset = {
    asset_id: number
    title: string
    chain_id?: number // TODO: Create a new type of IAsset which contains "collection:{}" attribute
    collection_contract: string
    collection_title: string
    attributes: IAssetAttribute[]
    description?: string
    image?: string
    owner?: string
    contract: string
    price: string
    views: number
    favorites: number
    isFavorite?: boolean // for the current user
}

export type IAssetAttribute = {
    trait_type: string
    value: string | number
}

/**
 * ACCOUNT
 */
export type IAccount = {
    walletAddress: string
    profileName?: string
    profileImage?: string
    bannerImage?: string
    collected?: IAsset[]
    creations?: IAsset[]
    favorites?: IAsset[]
    activity?: any
}

/**
 * TOKEN
 */
export type Token = {
    name: string,
    symbol: string,
    address: string,
    decimals: number
}