import React from 'react';
import styled from 'styled-components';
import { Box, Skeleton } from 'components';

const ItemWrapper = styled.div`
    min-width: 17rem;
    height: 100%;
    position: relative;
    border: 1px solid ${ ( { theme } ) => `${ theme.colors.cardBorderSubtle }` };
    border-radius: 0.75rem;
    margin: 0 1rem 1rem 0;
    overflow: hidden;
`;

const CollectionAssetSkeleton: React.FC = () => {
    return (
        <ItemWrapper>
            <Skeleton minWidth="100%" height="17rem" mb={ 2 }/>
            <Box px={ 2 }>
                <Skeleton width="100%" height={ 20 }/>
                <Skeleton width="100%" mt={ 2 } height={ 20 }/>
            </Box>
        </ItemWrapper>
    );
};

export default CollectionAssetSkeleton;