// Set of helper functions to facilitate wallet setup

import { ChainId, DEFAULT_CHAIN_ID, EXPLORER_URLS, RPC_URLS } from 'config/chain';

/**
 * Prompt the user to add BSC as a network on Metamask, or switch to BSC if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetwork = async () => {
    const provider = window.ethereum || {};
    if ( provider ) {
        try {
            provider.request && await provider.request( {
                method: 'wallet_addEthereumChain',
                params: [
                    {
                        chainId: `0x${ DEFAULT_CHAIN_ID.toString( 16 ) }`,
                        chainName: 'Echelon Mainnet',
                        nativeCurrency: {
                            name: 'CRO',
                            symbol: 'CRO',
                            decimals: 18,
                        },
                        rpcUrls: RPC_URLS,
                        blockExplorerUrls: [
                            `${ EXPLORER_URLS[ChainId.MAINNET] }`
                        ],
                    },
                ],
            } );
            return true;
        } catch ( error ) {
            console.error( 'Failed to setup the network in Metamask:', error );
            return false;
        }
    } else {
        console.error( 'Can\'t setup the network on metamask because window.ethereum is undefined' );
        return false;
    }
};