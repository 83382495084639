import { createGlobalStyle } from 'styled-components';
import { AppTheme } from 'config/theme';

declare module 'styled-components' {
    /* eslint-disable @typescript-eslint/no-empty-interface */
    export interface DefaultTheme extends AppTheme {
    }
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Poppins', sans-serif;
  }
  
  html, body {
    height: 100vh;
  }
  
  body {
    background-color: ${ ( { theme } ) => theme.colors.background };
    color: ${ ( { theme } ) => theme.colors.text };

    img {
      height: auto;
      max-width: 100%;
    }
  }
  
  #root {
    height: 100%;
  }
`;

export default GlobalStyle;
