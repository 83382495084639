import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { Box, Button, EthUsdPrice, Flex, Skeleton, SKELETON_H1_HEIGHT, Text, useModal } from 'components';
import ConnectModal from 'components/WalletModal/ConnectModal';
import { FetchStatus, IAsset } from 'config/constants/types';
import { getBalanceAmount } from 'utils/formatBalance';
import useAuth from 'hooks/useAuth';
import PlaceOfferModal from './PlaceOfferModal';
import BuyNowModal from './BuyNowModal';

interface PurchaseProps {
    asset?: IAsset
    assetDataFetchStatus: FetchStatus
}

const Purchase: React.FC<PurchaseProps> = ( props ) => {
    const { asset, assetDataFetchStatus } = props;
    const { account } = useWeb3React();
    const { login } = useAuth();
    const [ onPresentConnectModal ] = useModal( <ConnectModal login={ login }/> );
    const [ onPresentPlaceOfferModal ] = useModal( <PlaceOfferModal/> );
    const [ onPresentBuyNowModal ] = useModal( <BuyNowModal asset={ asset }/> );

    const price = getBalanceAmount( asset?.price );

    const handleBuyNowClicked = React.useCallback( () => {
        if ( account ) {
            onPresentBuyNowModal();
        } else {
            onPresentConnectModal();
        }
    }, [
        account,
        onPresentBuyNowModal,
        onPresentConnectModal,
    ] );

    const handleMakeOfferClicked = React.useCallback( () => {
        if ( account ) {
            onPresentPlaceOfferModal();
        } else {
            onPresentConnectModal();
        }
    }, [
        account,
        onPresentPlaceOfferModal,
        onPresentConnectModal,
    ] );

    return (
        <Box bordered my={ 4 }>
            <Text color="textSubtle">Current Price</Text>

            <Flex my={ 3 }>
                { assetDataFetchStatus === FetchStatus.PENDING ? (
                    <Skeleton width="5rem" height={ SKELETON_H1_HEIGHT }/>
                ) : (
                    <EthUsdPrice ethValue={ price }/>
                ) }
            </Flex>

            <Flex>
                <Button
                    disabled={ assetDataFetchStatus === FetchStatus.PENDING }
                    onClick={ handleMakeOfferClicked }
                >
                    Make offer
                </Button>
                <Button
                    disabled={ assetDataFetchStatus === FetchStatus.PENDING }
                    onClick={ handleBuyNowClicked }
                    ml={ 2 }
                >
                    Buy now
                </Button>
            </Flex>
        </Box>
    );
};

export default Purchase;