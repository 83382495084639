import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { useTooltip } from 'hooks/useTooltip';
import getExternalLinkProps from 'utils/getExternalLinkProps';

interface RoundedButtonWithTooltipProps {
    label: string,
    icon: ReactNode
    href?: string,
    externalLink?: boolean,
    onClick?: () => void
}

const RoundedButton = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    text-align: center;
    border: 0 none;
    border-radius: 50%;
    background: transparent;
    cursor: pointer;
    margin-right: 1rem;
    
    &:hover {
        box-shadow: rgb(0 0 0 / 10%) 0px 2px 10px;
    }
`;

const RoundedButtonWithTooltip: React.FC<RoundedButtonWithTooltipProps> = ( props ) => {
    const {
        label,
        icon,
        href,
        externalLink,
        onClick,
    } = props;

    const {
        targetRef,
        tooltip,
        tooltipVisible
    } = useTooltip(
        label, { placement: 'top', trigger: 'hover' },
    );

    const targetProp = href && externalLink ? { ...getExternalLinkProps() } : {};

    return (
        <div ref={ targetRef }>
            { tooltipVisible && tooltip }
            <RoundedButton href={ href || '#' } { ...targetProp } onClick={ onClick }>
                { React.isValidElement( icon ) && React.cloneElement( icon, {} ) }
            </RoundedButton>
        </div>
    );
};

RoundedButtonWithTooltip.defaultProps = {
    externalLink: true
};

export default RoundedButtonWithTooltip;