import React from 'react';
import Svg from '../Svg';
import { SvgProps } from '../types';

const Icon: React.FC<SvgProps> = ( props ) => {
    return (
        <Svg viewBox="0 0 32 32" { ...props }>
            <path d="m 20,10 h 5.22 A 11,11 0 0 0 5,16 H 3 A 13,13 0 0 1 26,7.68 V 4 h 2 v 8 h -8 z" />
            <path d="M 12,22 H 6.78 A 11,11 0 0 0 27,16 h 2 A 13,13 0 0 1 6,24.32 V 28 H 4 v -8 h 8 z" />
        </Svg>
    );
};

export default Icon;
