import { useCallback } from 'react';
import { ArgNotSetError, EnvVariableNotSetError } from 'errors';
import { signMessage } from '../utils/web3React';
import { useWeb3React } from '@web3-react/core';
import useWalletModal from '../components/WalletModal/useWalletModal';
import useAuth from './useAuth';
import JwtTokenError from '../errors/JwtTokenError';
import { getAuthToken, removeAuthToken, saveAuthToken } from '../utils/authToken';
import WalletNotConnectedError from '../errors/WalletNotConnectedError';
import { HTTP_STATUS_OK, HTTP_STATUS_UNAUTHORIZED } from '../config/constants/http';
import ApiFetchError from '../errors/ApiFetchError';
import { APIResponseData } from '../interfaces/APIResponse';
import ApiErrorUnauthorized from '../errors/ApiErrorUnauthorized';

export enum RequestMethod { GET, POST, PUT, DELETE}

const getRequestMethodTitle = ( arg: RequestMethod ) => {
    switch ( arg ) {
        case RequestMethod.POST:
            return 'POST';

        case RequestMethod.PUT:
            return 'PUT';

        case RequestMethod.DELETE:
            return 'DELETE';

        case RequestMethod.GET:
        default:
            return 'GET';
    }
};

const getHeadersWithJwtToken = ( headers: any, jwtToken: string ) => ({ ...headers, 'x-token': jwtToken });

const RETRYING_HEADER_KEY = 'x-retrying';

const DEFAULT_HEADERS = {
    'Content-Type': 'application/json;charset=utf-8'
};

const useFetchFromApi = () => {
    const { account, library } = useWeb3React();
    const { login, logout } = useAuth();
    const { onPresentConnectModal } = useWalletModal( login, logout );

    /**
     * Fetch from the API.
     * @return Response The entire raw response.
     */
    const fetchFromApi = useCallback(
        async ( endpoint: string, method: RequestMethod = RequestMethod.GET, requestBody = {}, headers = DEFAULT_HEADERS ): Promise<Response> => {
            const serverUrl = process.env.REACT_APP_API_SERVER_URL;
            if ( !serverUrl ) {
                throw new EnvVariableNotSetError( 'REACT_APP_API_SERVER_URL' );
            }

            if ( !endpoint ) {
                throw new ArgNotSetError( 'endpoint' );
            }

            if ( !endpoint.startsWith( '/' ) ) {
                endpoint = `/${ endpoint }`;
            }

            // POST Requests
            let body = {};
            if ( method === RequestMethod.POST ) {
                body = {
                    body: JSON.stringify( requestBody )
                };
            }

            // Handle headers
            delete headers[RETRYING_HEADER_KEY];

            const rawResponse = await fetch( `${ serverUrl }${ endpoint }`, {
                method: getRequestMethodTitle( method ),
                headers,
                ...body
            } );

            const statusCode = rawResponse.status;

            if ( statusCode !== HTTP_STATUS_OK ) {
                if ( statusCode === HTTP_STATUS_UNAUTHORIZED ) {
                    throw new ApiErrorUnauthorized( endpoint );
                }

                const json = await rawResponse.json();
                throw new ApiFetchError( json?.error );
            }

            return rawResponse;
        },
        []
    );

    /**
     * Fetch from the API.
     * @return The 'data' field of the JSON response.
     */
    const fetchDataFromApi = useCallback(
        async ( endpoint: string, method: RequestMethod = RequestMethod.GET, requestBody = {}, headers = DEFAULT_HEADERS ): Promise<APIResponseData> => {
            const rawResponse = await fetchFromApi( endpoint, method, requestBody, headers );
            const json = await rawResponse.json();
            return json?.data || {};
        },
        [
            account,
            library
        ]
    );

    /**
     * Fetch from the API including the JWT Token on the request.
     * @return The 'data' field of the JSON response.
     */
    const fetchDataFromApiUsingToken = useCallback(
        async ( endpoint: string, method: RequestMethod = RequestMethod.GET, requestBody = {}, headers = DEFAULT_HEADERS ): Promise<APIResponseData> => {

            if ( !account || !library ) {
                onPresentConnectModal();
                throw new WalletNotConnectedError();
            }

            try {
                const jwtToken = await getAuthTokenOrInitiateLogin();

                const rawResponse = await fetchFromApi( endpoint, method, requestBody, getHeadersWithJwtToken( headers, jwtToken ) );
                const json = await rawResponse.json();
                return json?.data;
            } catch ( err: any ) {
                if ( err instanceof ApiErrorUnauthorized ) {
                    console.warn( 'Invalid auth token. Renewing the old one...' );
                    removeAuthToken();
                    await getAuthTokenOrInitiateLogin();

                    // try again
                    if ( !headers[RETRYING_HEADER_KEY] ) {
                        return fetchDataFromApiUsingToken( endpoint, method, requestBody, {
                            ...headers,
                            [RETRYING_HEADER_KEY]: true
                        } );
                    }

                    throw err;
                } else {
                    throw err;
                }
            }
        },
        [
            account,
            library
        ]
    );

    const getAuthTokenOrInitiateLogin = useCallback(
        async (): Promise<string> => {
            let jwtToken = getAuthToken();

            if ( !jwtToken ) {
                const res = await fetchDataFromApi( `/user/initiateLogin`, RequestMethod.POST, {
                    wallet_address: account
                } );

                // If server requires user to sign the message.
                if ( res?.signMessage ) {
                    const signedMessage = await signMessage( library, account, res?.signMessage );

                    const verifyMessageRes = await fetchDataFromApi( `/user/verifyInitiateLogin`, RequestMethod.POST, {
                        signed_message: signedMessage,
                        wallet_address: account
                    } );

                    jwtToken = verifyMessageRes?.token;
                } else if ( res?.token ) {
                    jwtToken = res?.token;
                } else {
                    throw new JwtTokenError( 'Server answered an invalid login signature.' );
                }

                if ( !jwtToken ) {
                    throw new JwtTokenError( 'No such token!' );
                }

                saveAuthToken( jwtToken );
            }

            return jwtToken;
        },
        [
            account
        ]
    );

    return {
        fetchDataFromApi,
        fetchDataFromApiUsingToken
    };
};

export default useFetchFromApi;