import React from 'react';
import styled from 'styled-components';
import { Flex, Heading, PaddedRow, Page, PageHeader, TabButton } from 'components';
import { useParams } from 'react-router-dom';
import categories from './categories.json';
import { Category, EXPLORE_BASE_URL } from './types';
import RandomCollections from './components/RandomCollections';

const CategoriesWrapper = styled( Flex )`
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid ${ ( { theme } ) => theme.colors.cardBorderSubtle };
    padding: 0;
    margin-bottom: 2rem;
`;

const Explore: React.FC = () => {
    const { activeTab = 'all' } = useParams<{ activeTab: string }>();

    return (
        <>
            <Page>
                <PageHeader/>

                <Heading scale="lg" my={ 40 } textAlign="center">Explore Collections</Heading>

                <CategoriesWrapper>
                    { categories.map( ( category: Category ) => (
                        <TabButton
                            to={ `${ EXPLORE_BASE_URL }/${ category.slug }` }
                            key={ category.slug }
                            isActive={ activeTab === category.slug }
                        >
                            { category.title }
                        </TabButton>
                    ) ) }
                </CategoriesWrapper>

                <PaddedRow>
                    <RandomCollections activeTab={ activeTab }/>
                </PaddedRow>
            </Page>
        </>
    );
};

export default Explore;