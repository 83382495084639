import { useEffect, useState } from 'react';
import { FetchStatus, IAsset } from 'config/constants/types';
import useFetchFromApi from 'hooks/useFetchFromApi';

const useCollectionAssets = ( slug: string ) => {
    const { fetchDataFromApi } = useFetchFromApi();

    const [ collectionAssets, setCollectionAssets ] = useState<IAsset[]>( [] );
    const [ fetchStatus, setFetchStatus ] = useState<FetchStatus>( FetchStatus.PENDING );

    useEffect( () => {

        const fetchCollectionAssets = async () => {
            try {
                const response = await fetchDataFromApi( `/collection/${ slug }/assets` );

                setCollectionAssets( response as IAsset[] );
                setFetchStatus( FetchStatus.SUCCESS );
            } catch ( err ) {
                setFetchStatus( FetchStatus.FAILED );
                throw err;
            }
        };

        fetchCollectionAssets().catch( err => console.warn( 'Error fetching collection data:', err.message ) );
    }, [
        fetchDataFromApi,
        slug
    ] );

    return {
        collectionAssets,
        fetchStatus
    };
};


export default useCollectionAssets;