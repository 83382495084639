import React from 'react';
import { Accordion, Box, Container, Flex, Heading, IconButton, Image, Page, PageHeader, ShareIcon, Skeleton, SKELETON_H1_HEIGHT, Text } from 'components';
import { Link as RouterLink, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { COLLECTION_BASE_URL } from '../Collection/types';
import CollectionPropertiesSection from './components/CollectionPropertiesSection';
import CollectionAboutSection from './components/CollectionAboutSection';
import CollectionDetailsSection from './components/CollectionDetailsSection';
import AssetViews from './components/AssetViews';
import AssetFavorites from './components/AssetFavorites';
import AssetOwner from './components/AssetOwner';
import AssetAttributes from './components/AssetAttributes';
import AssetPriceHistory from './components/AssetPriceHistory';
import MoreFromCollection from './components/MoreFromCollection';
import ItemActivity from './components/ItemActivity';
import Purchase from './components/Purchase';
import useCollectionData from 'views/Collection/hooks/useCollectionData';
import useAssetData from './hooks/useAssetData';
import { FetchStatus } from '../../config/constants/types';
import { getAssetImageUrl } from '../../utils/assetImage';

const LeftBoxes = styled.div`
    margin-right: 2rem;
    width: auto;
    max-width: 25rem;
`;

const AssetImageWrapper = styled.div`
    width: 25rem;
    height: 25rem;
    overflow: hidden;
    border-radius: 16px;
    border: 1px solid #CCC;
    margin-bottom: 16px;
`;

const RightBoxes = styled.div`
    flex: 1;
`;

const StyledRouterLink = styled( RouterLink )`
    font-weight: bold;
    color: ${ ( { theme } ) => theme.colors.primary };
    margin-bottom: 1rem;
`;

const Asset: React.FC = () => {
    const { collectionSlug, assetId } = useParams<{ collectionSlug: string, assetId: string }>();
    const { collectionData, fetchStatus: collectionDataFetchStatus } = useCollectionData( collectionSlug );
    const { assetData, fetchStatus: assetDataFetchStatus } = useAssetData( collectionSlug, assetId );

    return (
        <>
            <PageHeader/>
            <Page padded>
                <Container>
                    <Flex alignItems="flex-start">
                        <LeftBoxes>
                            <AssetImageWrapper>
                                <Image src={ getAssetImageUrl( collectionSlug, assetId ) } alt=""/>
                            </AssetImageWrapper>

                            <Accordion>
                                <CollectionAboutSection
                                    collectionData={ collectionData }
                                    collectionDataFetchStatus={ collectionDataFetchStatus }
                                />
                                <CollectionPropertiesSection
                                    collectionData={ collectionData }
                                    collectionDataFetchStatus={ collectionDataFetchStatus }
                                />
                                <CollectionDetailsSection
                                    collectionData={ collectionData }
                                    collectionDataFetchStatus={ collectionDataFetchStatus }
                                    assetData={ assetData }
                                    assetDataFetchStatus={ assetDataFetchStatus }
                                />
                            </Accordion>
                        </LeftBoxes>

                        <RightBoxes>
                            <Flex alignItems="center" justifyContent="space-between">
                                { collectionDataFetchStatus === FetchStatus.PENDING ? (
                                    <Skeleton width='100%'/>
                                ) : (
                                    <StyledRouterLink to={ `${ COLLECTION_BASE_URL }/${ collectionData?.contract_address }` }>
                                        { collectionData?.title }
                                    </StyledRouterLink>
                                ) }
                                <IconButton variant="secondary" scale="sm">
                                    <ShareIcon color="primary"/>
                                </IconButton>
                            </Flex>

                            <Box>
                                { assetDataFetchStatus === FetchStatus.PENDING ? (
                                    <>
                                        <Skeleton width='100%' height={ SKELETON_H1_HEIGHT }/>
                                        <Skeleton width='100%' mt={ 2 }/>
                                    </>
                                ) : (
                                    <>
                                        <Heading scale="xl">{ assetData?.title }</Heading>
                                        <Text mt={ 2 }>{ assetData?.description }</Text>
                                    </>
                                ) }
                            </Box>

                            <Flex alignItems="center" justifyContent="flex-start" my={ 4 }>
                                <AssetOwner
                                    asset={ assetData }
                                    assetDataFetchStatus={ assetDataFetchStatus }
                                />
                                <AssetFavorites
                                    asset={ assetData }
                                    assetDataFetchStatus={ assetDataFetchStatus }
                                    mx={ 4 }
                                />
                                <AssetViews
                                    asset={ assetData }
                                    assetDataFetchStatus={ assetDataFetchStatus }
                                />
                            </Flex>

                            <Purchase
                                asset={ assetData }
                                assetDataFetchStatus={ assetDataFetchStatus }
                            />

                            <AssetAttributes
                                asset={ assetData }
                                assetDataFetchStatus={ assetDataFetchStatus }
                            />

                            <AssetPriceHistory/>
                        </RightBoxes>
                    </Flex>

                    <ItemActivity collectionData={ collectionData }/>
                    <MoreFromCollection
                        collectionSlug={ collectionSlug }
                        assetId={ assetId }
                    />
                </Container>
            </Page>
        </>
    );
};

export default Asset;
