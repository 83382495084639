import React from 'react';
import { FilterIcon, Flex, IconButton, Input, PaddedRow, Select } from 'components';
import styled from 'styled-components';

const SearchInput = styled( Input )`
    flex: 1;
    margin: 0 1rem;
`;

interface SearchBarProps {
    filtersVisible: boolean
    setFiltersVisible: ( arg: boolean ) => void
}

const SearchBar: React.FC<SearchBarProps> = ( props ) => {
    const { filtersVisible, setFiltersVisible } = props;

    return (
        <PaddedRow>
            <Flex my={ 2 }>
                <IconButton variant="text" onClick={ () => setFiltersVisible( !filtersVisible ) }>
                    <FilterIcon fill="#000"/>
                </IconButton>

                <SearchInput placeholder="Search by name or attribute"/>

                <Select>
                    <option value="recentlyListed">Recently listed</option>
                    <option value="recentlyListed">Recently created</option>
                    <option value="recentlyListed">Recently sold</option>
                    <option value="recentlyListed">Recently received</option>
                    <option value="recentlyListed">Ending soon</option>
                    <option value="priceLowToHigh">Price low to high</option>
                    <option value="priceLowToHigh">Price high to low</option>
                </Select>
            </Flex>
        </PaddedRow>
    );
};

export default SearchBar;