import { useEffect, useState } from 'react';
import { FetchStatus, ICollection } from 'config/constants/types';
import useFetchFromApi from 'hooks/useFetchFromApi';

const useCollectionData = ( slug: string ) => {
    const { fetchDataFromApi } = useFetchFromApi();

    const [ collectionData, setCollectionData ] = useState<ICollection | undefined>( undefined );
    const [ fetchStatus, setFetchStatus ] = useState<FetchStatus>( FetchStatus.PENDING );

    useEffect( () => {

        const fetchCollectionData = async () => {
            try {
                const response = await fetchDataFromApi( `/collection/${ slug }/data` );

                setCollectionData( response as ICollection );
                setFetchStatus( FetchStatus.SUCCESS );
            } catch ( err ) {
                setFetchStatus( FetchStatus.FAILED );
                throw err;
            }
        };

        fetchCollectionData().catch( err => console.warn( 'Error fetching collection data:', err.message ) );
    }, [
        fetchDataFromApi,
        slug
    ] );

    return {
        collectionData,
        fetchStatus
    };
};


export default useCollectionData;