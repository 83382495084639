import React from 'react';
import { ModalBackButton, ModalBody, ModalCloseButton, ModalContainer, ModalHeader, ModalTitle } from './styles';
import { ModalProps } from './types';
import getThemeValue from '../../utils/getThemeValue';
import { useTheme } from 'styled-components';

const Modal: React.FC<ModalProps> = ( {
                                          title,
                                          onDismiss,
                                          onBack,
                                          children,
                                          hideCloseButton = false,
                                          headerBackground = 'transparent',
                                          minWidth = '320px',
                                          ...props
                                      } ) => {
    const theme = useTheme();

    return (
        <ModalContainer minWidth={ minWidth } { ...props }>
            <ModalHeader background={ getThemeValue( `colors.${ headerBackground }`, headerBackground )( theme ) }>
                <ModalTitle>
                    { onBack && <ModalBackButton onBack={ onBack }/> }
                    <h2>{ title }</h2>
                </ModalTitle>
                { !hideCloseButton && <ModalCloseButton onDismiss={ onDismiss }/> }
            </ModalHeader>
            <ModalBody>{ children }</ModalBody>
        </ModalContainer>
    );
};

export default Modal;
