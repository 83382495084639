import React from 'react';
import { PaddedRow, Page, PageHeader } from 'components';
import { IAccount } from 'config/constants/types';
import ProfileHeader from '../components/ProfileHeader';
import CollectedItems from '../components/CollectedItems';
import ProfileBanner from '../components/ProfileBanner';
import Tabs from '../components/Tabs';

interface CollectedPageProps {
    account: IAccount
    activeTab?: string
}

const CollectedPage: React.FC<CollectedPageProps> = ( props ) => {
    const { activeTab, account } = props;

    return (
        <>
            <PageHeader/>
            <Page>
                <ProfileBanner account={ account }/>
                <ProfileHeader account={ account }/>
                <Tabs walletAddress={ account.walletAddress } activeTab={ activeTab }/>
                <PaddedRow>
                    <CollectedItems assets={ account.collected }/>
                </PaddedRow>
            </Page>
        </>
    );
};

export default CollectedPage;