import React from 'react';
import styled from 'styled-components';
import { ConnectWalletButton, Heading } from '../';
import { Flex } from '../Box';
import { useWeb3React } from '@web3-react/core';
import { Link } from 'react-router-dom';
import AccountDropdown from './AccountDropdown';

const Wrapper = styled.div`
    width: 100%;
    background-color: ${ ( { theme } ) => theme.colors.primary };
    padding: 1rem 1.5rem;
`;

const PageHeader: React.FC = () => {
    const { account } = useWeb3React();

    return (
        <Wrapper>
            <Flex alignItems="center" justifyContent="space-between">
                <Heading color="white">
                    <Link to="/">
                        ECH Marketplace
                    </Link>
                </Heading>

                <Flex alignItems="center" justifyContent="flex-end">
                    { !account ? <ConnectWalletButton/> : <AccountDropdown/> }
                </Flex>
            </Flex>
        </Wrapper>
    );
};

export default PageHeader;