import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { TabButtonProps } from './types';

const LinkButton = styled( Link )<{ isActive?: boolean }>`
    padding: 0.75rem 0;
    margin-right: 1.6rem;
    ${ ( { isActive, theme } ) => isActive ? `
        border-bottom: 4px solid black;
        color: black;
    ` : `
        color: ${ theme.colors.textSubtle };
    ` }
    
    &:hover {
        color: black;
    }
`;

const TabButton: React.FC<TabButtonProps> = ( props ) => {
    const { isActive, children, ...otherProps } = props;

    return (
        <LinkButton isActive={ isActive } { ...otherProps }>
            { children }
        </LinkButton>
    );
};

export default TabButton;