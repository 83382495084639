import { parseUnits } from 'ethers/lib/utils';

enum GAS_PRICE {
    default = '5000',
    fast = '6000',
    instant = '7000'
}

export const GAS_PRICE_GWEI = {
    default: parseUnits( GAS_PRICE.default, 'gwei' ).toString(),
    fast: parseUnits( GAS_PRICE.fast, 'gwei' ).toString(),
    instant: parseUnits( GAS_PRICE.instant, 'gwei' ).toString(),
};

export const DEFAULT_GAS_LIMIT = 85645;