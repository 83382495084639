import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { Box } from '../Box';

const StyledPage = styled( Box )<{ padded?: boolean }>`
    min-height: calc(100vh - 16px);
    
    ${ ( { theme, padded } ) => padded && `
        padding-top: 16px;
        padding-bottom: 16px;
       
        ${ theme.mediaQueries.sm } {
            padding-top: 24px;
            padding-bottom: 24px;
        }
        
        ${ theme.mediaQueries.lg } {
            padding-top: 32px;
            padding-bottom: 32px;
        } 
    ` }
`;

export const PageMeta: React.FC = () => {
    return (
        <Helmet>
            <title>ECH Marketplace</title>
            <meta property="og:title" content="ECH Marketplace"/>
            <meta property="og:description" content="Best Marketplace on Echelon Network"/>
            <meta property="og:image" content=""/>
        </Helmet>
    );
};

interface PageProps extends React.HTMLAttributes<HTMLDivElement> {
    padded?: boolean
    symbol?: string
}

const Page: React.FC<PageProps> = ( props ) => {
    const { children, ...otherProps } = props;
    return (
        <>
            <PageMeta/>
            <StyledPage { ...otherProps }>{ children }</StyledPage>
        </>
    );
};

Page.defaultProps = {
    padded: false
};

export default Page;
