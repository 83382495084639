import React, { useMemo } from 'react';
import useAuth from 'hooks/useAuth';
import { useWeb3React } from '@web3-react/core';
import styled from 'styled-components';
import WalletButton from './WalletButton';

const DisconnectButton = styled( WalletButton )`
    background-color: rgba(255, 255, 255, .8);
    border: 2px solid ${ ( { theme } ) => theme.colors.primary };
    color: ${ ( { theme } ) => theme.colors.primary };
    
    &:hover {
        background: #ffffff;
    }
`;

const DisconnectWalletButton = ( props: any ) => {
    const { account } = useWeb3React();
    const { logout } = useAuth();

    const shortWalletAddress = useMemo( () => account ? account.slice( -4 ) : null, [ account ] );

    return (
        <DisconnectButton onClick={ logout } { ...props }>
            { `...${ shortWalletAddress } - Disconnect` }
        </DisconnectButton>
    );
};

export default DisconnectWalletButton;
