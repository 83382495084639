import { SpaceProps } from 'styled-system';

// Directions
export const directions = {
    VERTICAL: 'vertical',
    HORIZONTAL: 'horizontal'
} as const;

export type Direction = typeof directions[keyof typeof directions];

// Disposition
export const dispositions = {
    LABEL_FIRST: 'labelFirst',
    INPUT_FIRST: 'inputFirst'
} as const;

export type Disposition = typeof dispositions[keyof typeof dispositions];

// Final props
export interface InputLabelProps extends SpaceProps {
    label: string
    hoverEffect?: boolean
    direction?: Direction
    disposition?: Disposition
}

