import React from 'react';
import styled from 'styled-components';
import { Box, EthereumIcon, Flex, HeartStrokedIcon, Image, IconButton, Link, Text } from 'components';
import { IAsset } from 'config/constants/types';
import { ASSET_BASE_URL } from 'views/Asset/types';
import { getAssetImageUrl } from 'utils/assetImage';
import { getFullDisplayBN } from 'utils/formatBalance';

const FavoriteButton = styled( IconButton )`
    display: none;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    box-shadow: 0px 0px 15px -5px #BBB;

    & svg {
        fill: ${ ( { theme } ) => `${ theme.colors.textSubtle }` };
        height: 14px;
    }
    
    &:hover {
        background: rgba(255, 255, 255, 1);
        & svg {
            fill: ${ ( { theme } ) => `${ theme.colors.failure }` };
        }
    }
`;

const ImageWrapper = styled.div`
    width: 100%;
    min-height: 8rem;
    max-height: 25rem;
    overflow: hidden;
    background-color: ${ ( { theme } ) => `${ theme.colors.cardBorderSubtle }` };
    
    & > img {
        min-height: 100%;
        max-height: 100%;
        min-width: 100%;
        max-width: 100%;
    }
`;

const ItemWrapper = styled.div`
    position: relative;
    border: 1px solid ${ ( { theme } ) => `${ theme.colors.cardBorderSubtle }` };
    border-radius: 0.75rem;
    margin: 0 1rem 1rem 0;
    overflow: hidden;
    
    &:hover {
        box-shadow: 4px 4px 7px -5px #BBB;
        border: 1px solid ${ ( { theme } ) => `${ theme.colors.cardBorder }` };
        
        & ${ FavoriteButton } {
            display: block;
        }
        
        & ${ ImageWrapper } img {
            transform: scale(1.15);
        }
    }
`;

const DetailsWrapper = styled( Flex )`
    padding: 1rem;
    align-items: flex-start;
    justify-content: space-between;
`;

interface CollectionAssetProps {
    asset: IAsset
}

const CollectionAsset: React.FC<CollectionAssetProps> = ( props ) => {
    const { asset } = props;

    const viewDetailsLink = `${ ASSET_BASE_URL }/${ asset.collection_contract }/${ asset.asset_id }`;

    return (
        <ItemWrapper>
            <ImageWrapper>
                <Link href={ viewDetailsLink }>
                    <Image src={ getAssetImageUrl( asset.collection_contract, asset.asset_id ) } alt=""/>
                </Link>
            </ImageWrapper>
            <DetailsWrapper>
                <Box>
                    <Text small color="textSubtle">{ asset.collection_title }</Text>
                    <Text small bold>{ asset.title }</Text>
                </Box>
                <Box>
                    <Text small color="textSubtle">Price</Text>
                    <Flex>
                        <EthereumIcon height={ 14 }/>
                        <Text small bold>{ getFullDisplayBN( asset.price, 18, 3 ) }</Text>
                    </Flex>
                </Box>
            </DetailsWrapper>
            <FavoriteButton scale="sm" variant="text">
                <HeartStrokedIcon/>
            </FavoriteButton>
        </ItemWrapper>
    );
};

export default CollectionAsset;