import React from 'react';
import styled from 'styled-components';
import { DiscordIcon, EtherScanIcon, FlagIcon, Flex, Heading, PaddedRow, RoundedButtonWithTooltip, Skeleton, Text, TwitterIcon, WorldIcon } from 'components';
import { FetchStatus, ICollection } from 'config/constants/types';
import { truncateAddress } from '../../../utils/addressHelpers';

export interface CollectionHeaderProps {
    collectionData?: ICollection
    dataFetchStatus: FetchStatus
}

const ButtonsWrapper = styled( Flex )`
    & > button {
        margin-right: 1rem;
        
        &:last-child {
            margin-right: 0;
        }
    }
`;

const CollectionHeader: React.FC<CollectionHeaderProps> = ( props ) => {
    const { collectionData, dataFetchStatus } = props;

    return (
        <PaddedRow mt={ 3 }>
            <Flex alignItems="center" justifyContent="space-between">
                { dataFetchStatus === FetchStatus.PENDING ? (
                    <Skeleton width={ 500 } height={ 45 }/>
                ) : (
                    <Heading scale="xl">{ collectionData?.title }</Heading>
                ) }

                <ButtonsWrapper>
                    { dataFetchStatus === FetchStatus.PENDING ? (
                        [ 1, 2, 3, 4, 5, 6 ].map( ( arg ) => (
                            <Skeleton
                                key={ arg }
                                variant='circle'
                                width='2.5rem'
                                height='2.5rem'
                                mr={ 2 }
                            />
                        ) )
                    ) : (
                        <>
                            <RoundedButtonWithTooltip
                                label="EtherScan"
                                icon={ <EtherScanIcon/> }
                                href={ `https://etherscan.io/address/${ collectionData?.contract_address }` }
                            />
                            { collectionData?.websiteUrl && (
                                <RoundedButtonWithTooltip
                                    label="Website"
                                    icon={ <WorldIcon/> }
                                    href={ collectionData.websiteUrl }
                                />
                            ) }
                            { collectionData?.discordInvite && (
                                <RoundedButtonWithTooltip
                                    label="Discord"
                                    icon={ <DiscordIcon/> }
                                    href={ `https://discord.gg/${ collectionData.discordInvite }` }
                                />
                            ) }
                            { collectionData?.twitterUsername && (
                                <RoundedButtonWithTooltip
                                    label="Twitter"
                                    icon={ <TwitterIcon/> }
                                    href={ `https://twitter.com/${ collectionData.twitterUsername }` }
                                />
                            ) }
                            <RoundedButtonWithTooltip
                                label="Report"
                                icon={ <FlagIcon/> }
                                onClick={ () => alert( 'Reporting...' ) }
                            />
                        </>
                    ) }
                </ButtonsWrapper>
            </Flex>

            { dataFetchStatus === FetchStatus.PENDING ? (
                <>
                    <Skeleton mt={ 2 } width={ 500 } height={ 20 }/>
                    <Skeleton mt={ 3 } width={ 500 } height={ 20 }/>
                </>
            ) : (
                <>
                    <Text mt={ 2 }>
                        By <strong>{ truncateAddress( collectionData?.creator, 6, 4 ) }</strong>
                    </Text>
                    <Text mt={ 3 }>
                        { collectionData?.description }
                    </Text>
                </>
            ) }

        </PaddedRow>
    );
};

export default CollectionHeader;