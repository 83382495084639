import React from 'react';
import styled from 'styled-components';
import { IAsset } from 'config/constants/types';
import { Box, Flex, Link, Text } from 'components';
import { ASSET_BASE_URL } from '../../Asset/types';

const ImageWrapper = styled.div`
    width: 100%;
    max-height: 25rem;
    overflow: hidden;
    background-color: ${ ( { theme } ) => `${ theme.colors.cardBorderSubtle }` };
    
    & > img {
        min-height: 100%;
        max-height: 100%;
        min-width: 100%;
        max-width: 100%;
    }
`;

const ItemWrapper = styled.div`
    position: relative;
    border: 1px solid ${ ( { theme } ) => `${ theme.colors.cardBorderSubtle }` };
    border-radius: 0.75rem;
    margin: 0 1rem 1rem 0;
    overflow: hidden;
    
    &:hover {
        box-shadow: 4px 4px 7px -5px #BBB;
        border: 1px solid ${ ( { theme } ) => `${ theme.colors.cardBorder }` };
        
        & ${ ImageWrapper } img {
            transform: scale(1.15);
        }
    }
`;

const DetailsWrapper = styled( Flex )`
    padding: 1rem;
    align-items: flex-start;
    justify-content: space-between;
`;

interface CollectionAssetProps {
    item: IAsset
}

const Asset: React.FC<CollectionAssetProps> = ( props ) => {
    const { item } = props;

    const viewDetailsLink = `${ ASSET_BASE_URL }/${ item.contract }`;

    return (
        <ItemWrapper>
            <ImageWrapper>
                <Link href={ viewDetailsLink }>
                    <img src={ item.image } alt=""/>
                </Link>
            </ImageWrapper>
            <DetailsWrapper>
                <Box>
                    <Text small color="textSubtle">{ item.collection_title }</Text>
                    <Text small bold>{ item.title }</Text>
                </Box>
            </DetailsWrapper>
        </ItemWrapper>
    );
};

export default Asset;