import React from 'react';
import { SpaceProps } from 'styled-system';
import { Flex, HeartFilledIcon, HeartStrokedIcon, IconButton, LoadingIcon, Skeleton, Text } from 'components';
import { FetchStatus, IAsset } from 'config/constants/types';
import { formatNumber } from 'utils/formatBalance';
import useFetchFromApi, { RequestMethod } from 'hooks/useFetchFromApi';
import { useWeb3React } from '@web3-react/core';
import { toast } from 'react-hot-toast';
import WalletNotConnectedError from '../../../errors/WalletNotConnectedError';

export interface AssetFavoritesProps extends SpaceProps {
    asset?: IAsset
    assetDataFetchStatus: FetchStatus
}

const AssetFavorites: React.FC<AssetFavoritesProps> = ( props ) => {
    const { asset, assetDataFetchStatus } = props;
    const { fetchDataFromApiUsingToken } = useFetchFromApi();
    const { account } = useWeb3React();

    const [ isFavorite, setFavorite ] = React.useState<boolean>( asset?.isFavorite || false );
    const [ totalFavorites, setTotalFavorites ] = React.useState<number>( asset?.favorites || 0 );
    const [ saving, setSaving ] = React.useState( false );

    React.useEffect( () => {
        setTotalFavorites( asset?.favorites || 0 );
    }, [
        asset
    ] );

    const handleClick = React.useCallback(
        async () => {
            try {
                setSaving( true );

                const res = await fetchDataFromApiUsingToken(
                    `/asset/saveFavorite/${ asset.collection_contract }/${ asset.asset_id }`,
                    RequestMethod.POST,
                    {
                        wallet_address: account
                    }
                );

                const isFavoriteNow = res?.isFavorite;

                setFavorite( isFavoriteNow );
                setTotalFavorites( res?.favorites || 0 );

                if ( isFavoriteNow ) {
                    toast.success( 'Saved as favorite' );
                } else {
                    toast.success( 'Removed from favorites' );
                }
            } catch ( err: any ) {
                console.error( 'Error saving favorite', err?.message );
                if ( err instanceof WalletNotConnectedError ) {
                    // ?
                } else {
                    toast.error( 'Error saving favorite: ' + err?.message );
                }
            } finally {
                setSaving( false );
            }
        }, [
            account,
            asset
        ] );

    return (
        <Flex { ...props }>
            <IconButton
                scale="sm"
                variant='text'
                disabled={ saving }
                onClick={ handleClick }
            >
                { saving ? (
                    <LoadingIcon color='textSubtle'/>
                ) : (
                    assetDataFetchStatus === FetchStatus.PENDING ? (
                        <HeartStrokedIcon color="textSubtle"/>
                    ) : isFavorite ? (
                        <HeartFilledIcon fill="failure"/>
                    ) : (
                        <HeartStrokedIcon color="textSubtle"/>
                    )
                ) }
            </IconButton>
            { assetDataFetchStatus === FetchStatus.PENDING ? (
                <Skeleton/>
            ) : (
                <Text color="textSubtle">
                    { formatNumber( totalFavorites, 0, 0 ) } favorites
                </Text>
            ) }
        </Flex>
    );
};

export default AssetFavorites;