import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Accordion, AccordionSection, Button, EthUsdPrice, Flex, Hr, Input, LoadingIcon, Table, TBody, Td, Text, Th, THead, Tr, WalletIcon } from 'components';
import { ModalBody, ModalCloseButton, ModalContainer, ModalHeader, ModalTitle } from 'components/Modal';
import { IAsset } from 'config/constants/types';
import { getBalanceAmount } from 'utils/formatBalance';
import useBuy from '../../../hooks/useBuy';
import { toast } from 'react-hot-toast';

interface BuyNowModalProps {
    onDismiss?: () => void
    asset?: IAsset
}

const StyledModalBody = styled( ModalBody )`
    background: ${ ( { theme } ) => theme.modal.lightBackground };
`;

const QuantityInput = styled( Input )`
    width: 75px;
`;

const WalletInput = styled( Input )`
    width: 100%;
`;

const BuyNowModal: React.FC<BuyNowModalProps> = ( props ) => {
    const { onDismiss, asset } = props;

    const { onBuy } = useBuy();
    const [ buying, setBuying ] = useState( false );
    const [ quantity, setQuantity ] = useState( 1 );
    const quantityInputRef = useRef<HTMLInputElement>( null );

    const assetPrice = getBalanceAmount( asset?.price );

    const handleQuantityChanged = () => {
        const newQuantity = parseInt( quantityInputRef?.current?.value );

        if ( isNaN( newQuantity ) || newQuantity <= 0 ) {
            setQuantity( 1 );
            return;
        }

        setQuantity( newQuantity );
    };

    const handleCompleteCheckoutClicked = async () => {
        try {
            setBuying( true );

            await onBuy(
                asset?.collection_contract,
                asset?.asset_id,
                quantity
            );

            toast.success( 'Purchase successful!', { duration: 10000 } );
        } catch ( e ) {
            console.error( e );
            toast.error( 'Purchase failed.' );
        } finally {
            setBuying( false );
        }
    };

    return (
        <ModalContainer minWidth="320px">
            <ModalHeader>
                <ModalTitle>
                    <h2>Complete checkout</h2>
                </ModalTitle>
                <ModalCloseButton onDismiss={ onDismiss }/>
            </ModalHeader>
            <StyledModalBody>
                { asset ? (
                    <>
                        <Table>
                            <THead>
                                <Tr>
                                    <Th>Item</Th>
                                    <Th>Price</Th>
                                    <Th>Quantity</Th>
                                    <Th>Total</Th>
                                </Tr>
                            </THead>
                            <TBody>
                                <Tr>
                                    <Td>{ asset?.title }</Td>
                                    <Td>
                                        <EthUsdPrice
                                            ethValue={ assetPrice }
                                            scale='sm'
                                        />
                                    </Td>
                                    <Td>
                                        <QuantityInput
                                            type='number'
                                            defaultValue={ 1 }
                                            ref={ quantityInputRef }
                                            min={ 1 }
                                            max={ 100 }
                                            onChange={ handleQuantityChanged }
                                        />
                                    </Td>
                                    <Td>
                                        <EthUsdPrice
                                            ethValue={ assetPrice.times( quantity ) }
                                            scale='sm'
                                        />
                                    </Td>
                                </Tr>
                            </TBody>
                        </Table>

                        <Hr mt={ 2 }/>

                        <Accordion
                            bordered={ false }
                            borderedSections={ false }
                        >
                            <AccordionSection
                                label="Send to a different wallet"
                                icon={ <WalletIcon/> }
                            >
                                <WalletInput
                                    placeholder="e.g. 0x1ed3... or destination.eth"
                                />
                                <Text
                                    small
                                    textWrap
                                    color='textSubtle'
                                    mt={ 1 }
                                    textAlign='center'
                                >
                                    Make sure you&apos;re sending to the correct address as lost<br/>items can&apos;t be recovered.
                                </Text>
                            </AccordionSection>
                        </Accordion>

                        <Hr mt={ 2 }/>

                        <Flex justifyContent="center">
                            <Button
                                onClick={ handleCompleteCheckoutClicked }
                                mt={ 20 }
                                isLoading={ buying }
                            >
                                { buying && <LoadingIcon color='white' mr={ 2 }/> }
                                Complete checkout
                            </Button>
                        </Flex>
                    </>
                ) : (
                    <div>Asset not loaded yet.</div>
                ) }
            </StyledModalBody>
        </ModalContainer>
    );
};

export default BuyNowModal;
