import React from 'react';
import Svg from '../Svg';
import { SvgProps } from '../types';

const Icon: React.FC<SvgProps> = ( props ) => {
    return (
        <Svg viewBox="0 0 24 24" { ...props }>
            <path d="M3 15h8v-2H3v2zm0 4h8v-2H3v2zm0-8h8V9H3v2zm0-6v2h8V5H3zm10 0h8v14h-8V5z"/>
        </Svg>
    );
};

export default Icon;
