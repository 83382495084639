import React from 'react';
import { truncateAddress } from 'utils/addressHelpers';
import { Flex, Link, Skeleton, Text } from 'components';
import { FetchStatus, IAsset } from 'config/constants/types';
import { ACCOUNT_BASE_URL } from '../../Account/types';

export interface AssetOwnerProps {
    asset?: IAsset
    assetDataFetchStatus: FetchStatus
}

const AssetOwner: React.FC<AssetOwnerProps> = ( props ) => {
    const { asset, assetDataFetchStatus } = props;
    const owner = asset?.owner || '';

    return (
        <Flex>
            <Text color="textSubtle">
                Owned by
            </Text>
            { assetDataFetchStatus === FetchStatus.PENDING || !owner ? (
                <Skeleton width="7rem" ml={ 2 }/>
            ) : (
                <Link href={ `${ ACCOUNT_BASE_URL }/${ owner }` } ml={ 1 }>
                    { truncateAddress( owner ) }
                </Link>
            ) }
        </Flex>
    );
};

export default AssetOwner;