import React from 'react';
import Svg from '../Svg';
import { SvgProps } from '../types';

const Icon: React.FC<SvgProps> = ( props ) => {
    return (
        <Svg viewBox="0 0 463 463" { ...props }>
            <path d="M447.5,176H447V95.5c0-17.369-14.131-31.5-31.5-31.5h-384C14.131,64,0,78.131,0,95.5v272C0,384.869,14.131,399,31.5,399
				h384c17.369,0,31.5-14.131,31.5-31.5V287h0.5c8.547,0,15.5-6.953,15.5-15.5v-80C463,182.953,456.047,176,447.5,176z M432,367.5
				c0,9.098-7.402,16.5-16.5,16.5h-384c-9.098,0-16.5-7.402-16.5-16.5v-0.499h16.506c4.142,0,7.5-3.357,7.5-7.5s-3.358-7.5-7.5-7.5
				H15V111h16.5c4.142,0,7.5-3.357,7.5-7.5S35.642,96,31.5,96H15v-0.5C15,86.402,22.402,79,31.5,79h384
				c9.098,0,16.5,7.402,16.5,16.5V176h-96.5c-30.603,0-55.5,24.897-55.5,55.5c0,30.603,24.897,55.5,55.5,55.5H432V367.5z M448,271.5
				c0,0.275-0.224,0.5-0.5,0.5h-7.981c-0.007,0-0.013-0.001-0.019-0.001c-0.006,0-0.013,0.001-0.019,0.001H335.5
				c-22.332,0-40.5-18.168-40.5-40.5c0-22.332,18.168-40.5,40.5-40.5h103.961c0.013,0,0.026,0.002,0.039,0.002
				s0.026-0.002,0.039-0.002h7.961c0.276,0,0.5,0.225,0.5,0.5V271.5z"/>
            <path d="M335.5,208c-12.958,0-23.5,10.542-23.5,23.5s10.542,23.5,23.5,23.5s23.5-10.542,23.5-23.5S348.458,208,335.5,208z
				 M335.5,240c-4.687,0-8.5-3.813-8.5-8.5s3.813-8.5,8.5-8.5s8.5,3.813,8.5,8.5S340.187,240,335.5,240z"/>
            <path d="M63.5,111h24c4.142,0,7.5-3.357,7.5-7.5S91.642,96,87.5,96h-24c-4.142,0-7.5,3.357-7.5,7.5S59.358,111,63.5,111z"/>
            <path d="M119.5,111h24c4.142,0,7.5-3.357,7.5-7.5s-3.358-7.5-7.5-7.5h-24c-4.142,0-7.5,3.357-7.5,7.5S115.358,111,119.5,111z"/>
            <path d="M175.5,111h24c4.142,0,7.5-3.357,7.5-7.5s-3.358-7.5-7.5-7.5h-24c-4.142,0-7.5,3.357-7.5,7.5S171.358,111,175.5,111z"/>
            <path d="M231.5,111h24c4.142,0,7.5-3.357,7.5-7.5s-3.358-7.5-7.5-7.5h-24c-4.142,0-7.5,3.357-7.5,7.5S227.358,111,231.5,111z"/>
            <path d="M287.5,111h24c4.142,0,7.5-3.357,7.5-7.5s-3.358-7.5-7.5-7.5h-24c-4.142,0-7.5,3.357-7.5,7.5S283.358,111,287.5,111z"/>
            <path d="M343.5,111h24c4.142,0,7.5-3.357,7.5-7.5s-3.358-7.5-7.5-7.5h-24c-4.142,0-7.5,3.357-7.5,7.5S339.358,111,343.5,111z"/>
            <path d="M399.5,111h0.5v0.5c0,4.143,3.358,7.5,7.5,7.5c4.142,0,7.5-3.357,7.5-7.5v-8c0-4.143-3.358-7.5-7.5-7.5h-8
				c-4.142,0-7.5,3.357-7.5,7.5S395.358,111,399.5,111z"/>
            <path d="M407.5,136c-4.142,0-7.5,3.357-7.5,7.5v16c0,4.143,3.358,7.5,7.5,7.5c4.142,0,7.5-3.357,7.5-7.5v-16
				C415,139.357,411.642,136,407.5,136z"/>
            <path d="M87.506,352.001h-24c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5h24c4.142,0,7.5-3.357,7.5-7.5
				S91.648,352.001,87.506,352.001z"/>
            <path d="M143.506,352.001h-24c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5h24c4.142,0,7.5-3.357,7.5-7.5
				S147.648,352.001,143.506,352.001z"/>
            <path d="M199.506,352.001h-24c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5h24c4.142,0,7.5-3.357,7.5-7.5
				S203.648,352.001,199.506,352.001z"/>
            <path d="M255.506,352.001h-24c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5h24c4.142,0,7.5-3.357,7.5-7.5
				S259.648,352.001,255.506,352.001z"/>
            <path d="M311.506,352.001h-24c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5h24c4.142,0,7.5-3.357,7.5-7.5
				S315.648,352.001,311.506,352.001z"/>
            <path d="M343.506,367.001h24c4.142,0,7.5-3.357,7.5-7.5s-3.358-7.5-7.5-7.5h-24c-4.142,0-7.5,3.357-7.5,7.5
				S339.364,367.001,343.506,367.001z"/>
            <path d="M399.506,367.001h8c4.142,0,7.5-3.357,7.5-7.5v-8c0-4.143-3.358-7.5-7.5-7.5c-4.142,0-7.5,3.357-7.5,7.5v0.5h-0.5
				c-4.142,0-7.5,3.357-7.5,7.5S395.364,367.001,399.506,367.001z"/>
            <path d="M407.506,327.001c4.142,0,7.5-3.357,7.5-7.5v-16c0-4.143-3.358-7.5-7.5-7.5c-4.142,0-7.5,3.357-7.5,7.5v16
				C400.006,323.644,403.364,327.001,407.506,327.001z"/>
        </Svg>
    );
};

export default Icon;
