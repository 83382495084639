import React from 'react';
import styled from 'styled-components';
import { Flex } from '../../../components/Box';

const Row = styled( Flex )`
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
`;

const SpacedBetweenRow: React.FC = ( props ) => <Row>{ props?.children }</Row>;

export default SpacedBetweenRow;