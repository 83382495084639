import React from 'react';
import { Flex, Heading, IconButton, Input, InputLabel, PaddedRow, Page, PageHeader, ShareIcon, Text, Textarea } from 'components';
import { IAccount } from 'config/constants/types';
import styled from 'styled-components';

interface CollectedPageProps {
    account: IAccount
}

const WalletWrapper = styled( Flex )`
    align-items: center;
    justify-content: space-between;
    padding: 0.3rem 0.5rem;
    border-radius: 4px;
    background-color: ${ ( { theme } ) => theme.colors.backgroundDisabled };
    border: 1px solid ${ ( { theme } ) => theme.colors.cardBorder };
`;

const SettingsPage: React.FC<CollectedPageProps> = ( props ) => {
    const { account } = props;

    return (
        <>
            <PageHeader/>
            <Page>
                <PaddedRow pt={ 50 }>
                    <Heading>Profile details</Heading>

                    <InputLabel direction='vertical' label="Username" mb={ 3 }>
                        <Input placeholder="Enter username"/>
                    </InputLabel>

                    <InputLabel direction='vertical' label="Bio" mb={ 3 }>
                        <Textarea placeholder="Enter Bio" rows={ 4 } cols={ 22 }/>
                    </InputLabel>

                    <InputLabel direction='vertical' label="Email address" mb={ 3 }>
                        <Input placeholder="Enter email"/>
                    </InputLabel>

                    <InputLabel direction='vertical' label="Links" mb={ 3 }>
                        <Input placeholder="Instagram"/>
                        <Input placeholder="yourWebsite.io"/>
                    </InputLabel>

                    <InputLabel direction='vertical' label="Wallet address">
                        <WalletWrapper>
                            <Text>{ account.walletAddress }</Text>
                            <IconButton scale="sm" variant="text" ml={ 2 }>
                                <ShareIcon color="textSubtle"/>
                            </IconButton>
                        </WalletWrapper>
                    </InputLabel>
                </PaddedRow>
            </Page>
        </>
    );
};

export default SettingsPage;