import { useCallback } from 'react';
import { useMarketplaceContract } from 'hooks/useContract';
import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice';
import { calculateGasMargin } from 'utils/calculateGasMargin';

const useBuy = () => {
    const marketplaceContract = useMarketplaceContract();
    const { callWithGasPrice } = useCallWithGasPrice();


    const handleBuy = useCallback(
        async ( collectionAddress: string, assetId: number, quantity: number ) => {
            const estimatedGas = await marketplaceContract.estimateGas.buy( collectionAddress, assetId, quantity );
            const gasLimit = calculateGasMargin( estimatedGas, 10 );

            const tx = await callWithGasPrice(
                marketplaceContract,
                'buy',
                [
                    collectionAddress,
                    assetId,
                    quantity
                ], {
                    gasLimit: gasLimit,
                }
            );
            const receipt = await tx.wait();
            return receipt.status;

        }, [ callWithGasPrice, marketplaceContract ]
    );

    return { onBuy: handleBuy };
};

export default useBuy;
