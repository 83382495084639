import { useCallback, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
import { getErc20Contract } from 'utils/contractHelpers';
import { FetchStatus } from 'config/constants/types';
import { BIG_ZERO } from 'utils/bigNumber';

const useTokenBalance = () => {
    const { account } = useWeb3React();

    const [ balance, setBalance ] = useState<BigNumber>( BIG_ZERO );
    const [ fetchStatus, setFetchStatus ] = useState<FetchStatus | null>( null );

    const fetchTokenBalance = useCallback(
        async ( tokenAddress: string ) => {
            const contract = getErc20Contract( tokenAddress );

            setFetchStatus( FetchStatus.PENDING );

            try {
                const res = await contract.balanceOf( account );
                setBalance( new BigNumber( res.toString() ) );
                setFetchStatus( FetchStatus.SUCCESS );
            } catch ( e ) {
                console.error( e );
                setBalance( BIG_ZERO );
                setFetchStatus( FetchStatus.FAILED );
            }

            return {
                balance,
                fetchStatus
            };
        }, [
            account
        ]
    );

    return {
        balance,
        fetchStatus,
        fetchTokenBalance,
    };
};

export default useTokenBalance;
