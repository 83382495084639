import { useEffect, useState } from 'react';
import { FetchStatus } from '../config/constants/types';

/**
 * TODO: Memo the ETH/USD price.
 */

const useEthUsdPrice = () => {
    const [ fetchStatus, setFetchStatus ] = useState<FetchStatus>( FetchStatus.PENDING );
    const [ ethUsdPrice, setEthUsdPrice ] = useState( 0 );

    useEffect( () => {
        const fetchPrice = async () => {
            const response = await fetch( `https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd` );
            const json = await response.json();
            return json?.ethereum?.usd;
        };

        fetchPrice()
            .then( res => {
                setEthUsdPrice( res );
                setFetchStatus( FetchStatus.SUCCESS );
            } )
            .catch( ( err: any ) => {
                console.warn( 'Failed fetching ETH/USD price', err?.message );
                setEthUsdPrice( 0 );
                setFetchStatus( FetchStatus.FAILED );
            } );
    }, [] );

    return {
        fetchStatus,
        ethUsdPrice,
    };
};

export default useEthUsdPrice;