import styled from 'styled-components';
import { space } from 'styled-system';
import { RadioProps, scales } from './types';

const getScale = ( { scale }: RadioProps ) => {
    switch ( scale ) {
        case scales.SM:
            return '18px';
        case scales.MD:
        default:
            return '24px';
    }
};

const getCheckedScale = ( { scale }: RadioProps ) => {
    switch ( scale ) {
        case scales.SM:
            return '6px';
        case scales.MD:
        default:
            return '12px';
    }
};

const Radio = styled.input.attrs( { type: 'radio' } )<RadioProps>`
  appearance: none;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  display: inline-block;
  min-height: ${ getScale };
  height: ${ getScale };
  min-width: ${ getScale };
  width: ${ getScale };
  vertical-align: middle;
  transition: background-color 0.2s ease-in-out;
  border: 1px solid ${ ( { theme } ) => theme.colors.inputBorder };
  border-radius: 50%;
  background-color: ${ ( { theme } ) => theme.colors.inputBackground };
  box-shadow: ${ ( { theme } ) => theme.shadows.inset };

  &:after {
    border-radius: 50%;
    content: "";
    height: ${ getCheckedScale };
    left: 5px;
    position: absolute;
    top: 5px;
    width: ${ getCheckedScale };
  }

  &:focus {
    outline: none;
  }

  &:checked {
    background-color: white;
    &:after {
      background-color: ${ ( { theme } ) => theme.colors.primary };
    }
  }

  &:disabled {
    cursor: default;
    opacity: 0.6;
  }
  ${ space }
`;

Radio.defaultProps = {
    scale: scales.MD,
    m: 0,
};

export default Radio;
