import React from 'react';
import styled from 'styled-components';
import { EthereumIcon, FlagIcon, Flex, Heading, PaddedRow, RoundedButtonWithTooltip, ShareIcon, Text } from 'components';
import { IAccount } from 'config/constants/types';
import { truncateAddress } from 'utils/addressHelpers';

export interface ProfileHeaderProps {
    account: IAccount
}

const ButtonsWrapper = styled( Flex )`
    & > button {
        margin-right: 1rem;
        
        &:last-child {
            margin-right: 0;
        }
    }
`;

const ProfileHeader: React.FC<ProfileHeaderProps> = ( props ) => {
    const { account } = props;

    return (
        <PaddedRow mt={ 3 }>
            <Flex alignItems="center" justifyContent="space-between">
                <Heading scale="lg">{ account?.profileName || 'Unnamed' }</Heading>

                <ButtonsWrapper>
                    <RoundedButtonWithTooltip
                        label="Share"
                        icon={ <ShareIcon/> }
                    />
                    <RoundedButtonWithTooltip
                        label="Report"
                        icon={ <FlagIcon/> }
                        href="https://echswap.finance"
                    />
                </ButtonsWrapper>
            </Flex>
            <Flex>
                <EthereumIcon/>
                <Text color="textSubtle" mt={ 2 }>
                    { truncateAddress( account.walletAddress, 6, 6 ) }
                </Text>
            </Flex>
        </PaddedRow>
    );
};

export default ProfileHeader;