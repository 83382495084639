import React from 'react';
import { Flex, PaddedRow, TabButton } from 'components';
import { ACCOUNT_ACTIVITY_URI, ACCOUNT_BASE_URL, ACCOUNT_COLLECTED_URI, ACCOUNT_CREATIONS_URI, ACCOUNT_FAVORITES_URI } from '../types';

interface ILink {
    title: string,
    href: string,
    isDefault?: boolean
}

interface TabsProps {
    walletAddress: string
    activeTab?: string
}

const Tabs: React.FC<TabsProps> = ( props ) => {
    const { walletAddress, activeTab } = props;

    const links: ILink[] = [
        { title: 'Collected', href: ACCOUNT_COLLECTED_URI, isDefault: true },
        { title: 'Created', href: ACCOUNT_CREATIONS_URI },
        { title: 'Favorited', href: ACCOUNT_FAVORITES_URI },
        { title: 'Activity', href: ACCOUNT_ACTIVITY_URI },
    ];

    return (
        <PaddedRow my={ 2 }>
            <Flex alignItems="flex-start" justifyContent="flex-start">
                { links.map( ( link: ILink ) => (
                        <TabButton
                            key={ link.href }
                            isActive={ activeTab ? activeTab === link.href : link.isDefault }
                            to={ `${ ACCOUNT_BASE_URL }/${ walletAddress }/${ link.href }` }
                        >
                            { link.title }
                        </TabButton>
                    )
                ) }
            </Flex>
        </PaddedRow>

    );
};

export default Tabs;