import React from 'react';
import { Text } from '../Text';
import { Direction, directions, dispositions, InputLabelProps } from './types';
import styled from 'styled-components';
import { space } from 'styled-system';

const StyledLabel = styled.label<{ direction?: Direction, hoverEffect?: boolean }>`
    display: flex;
    
    ${ ( { direction } ) => direction === directions.VERTICAL ? `
        flex-direction: column;
        align-items: flex-start;
    ` : `
        align-items: center;
    ` };
    
    justify-content: space-between;
    border: 0px none;
    
    ${ ( { hoverEffect, theme } ) => hoverEffect && `
        border-radius: 1rem;
        cursor: pointer;
        &:hover {
            background-color: ${ theme.colors.inputHoverBackground };
        }
    ` }
    
    ${ space }
`;

const InputLabel: React.FC<InputLabelProps> = ( props ) => {
    const { label, disposition, children, ...otherProps } = props;

    const renderLabel = () => (
        <Text color="textSubtle">{ label }</Text>
    );

    return (
        <StyledLabel { ...otherProps }>
            { disposition === 'labelFirst' && renderLabel() }
            { children }
            { disposition === 'inputFirst' && renderLabel() }
        </StyledLabel>
    );
};

InputLabel.defaultProps = {
    direction: directions.HORIZONTAL,
    disposition: dispositions.LABEL_FIRST,
    hoverEffect: false
};

export default InputLabel;