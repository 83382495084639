import { ChainId, DEFAULT_CHAIN_ID } from '../config/chain';
import { IAddress } from 'config/constants/types';

export const getAddress = ( address: IAddress ): string => {
    return (address[DEFAULT_CHAIN_ID] ? address[DEFAULT_CHAIN_ID] : address[ChainId.MAINNET]) as string;
};

export const truncateAddress = ( address?: string, startLength = 6, endLength = 4 ) => {
    if ( typeof address !== 'string' )
        return '';

    return `${ address?.substring( 0, startLength ) }...${ address?.substring( address.length - endLength ) }`;
};
