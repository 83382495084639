import React from 'react';
import styled from 'styled-components';
import { GridLayout, Skeleton, Text } from 'components';
import CollectionAsset from './CollectionAsset';
import useCollectionAssets from '../hooks/useCollectionAssets';
import { FetchStatus } from '../../../config/constants/types';
import CollectionAssetSkeleton from './CollectionAssetSkeleton';

const Wrapper = styled.div`

`;

interface CollectionItemsProps {
    collectionSlug: string
}

const CollectionItems: React.FC<CollectionItemsProps> = ( props ) => {
    const { collectionSlug } = props;
    const { collectionAssets, fetchStatus } = useCollectionAssets( collectionSlug );

    return (
        <Wrapper>
            { fetchStatus === FetchStatus.PENDING ? (
                <Skeleton my={ 3 } width={ 50 } height={ 25 }/>
            ) : (
                <Text color="textSubtle" my={ 3 }>{ collectionAssets.length } items</Text>
            ) }
            <GridLayout>
                { fetchStatus === FetchStatus.PENDING ? (
                    [ 1, 2, 3, 4, 5 ].map( ( arg ) =>
                        <CollectionAssetSkeleton
                            key={ arg }
                        />
                    )
                ) : (
                    collectionAssets.map( asset => (
                        <CollectionAsset
                            key={ Math.random() }
                            asset={ asset }
                        />
                    ) )
                ) }
            </GridLayout>
        </Wrapper>
    );
};

export default CollectionItems;