import React from 'react';
import styled from 'styled-components';
import { Box, EthereumIcon, Flex, Heading, PaddedRow, Skeleton, Text } from 'components';
import { FetchStatus, ICollection } from 'config/constants/types';
import { SKELETON_H2_HEIGHT } from 'components/Skeleton/types';
import { getFullDisplayBN } from 'utils/formatBalance';

export interface CollectionHeaderProps {
    collectionData?: ICollection
    dataFetchStatus: FetchStatus
}

const Wrapper = styled( Flex )`
    align-items: center;
    justify-content: flex-start;
    
    & > div {
        margin-right: 1.5rem;
        
        &:last-child {
            margin-right: 0;
        }
    }
`;

const CollectionNumbers: React.FC<CollectionHeaderProps> = ( props ) => {
    const { dataFetchStatus, collectionData } = props;

    return (
        <PaddedRow mt={ 4 }>
            <Wrapper>
                <Box>
                    { dataFetchStatus === FetchStatus.PENDING ? (
                        <Skeleton height={ SKELETON_H2_HEIGHT } width={ 40 }/>
                    ) : (
                        <Heading scale="md">{ collectionData?.stats.totalSupply }</Heading>
                    ) }
                    <Text color="textSubtle">items</Text>
                </Box>
                <Box>
                    { dataFetchStatus === FetchStatus.PENDING ? (
                        <Skeleton height={ SKELETON_H2_HEIGHT } width={ 40 }/>
                    ) : (
                        <Heading scale="md">{ collectionData?.stats.numOwners }</Heading>
                    ) }
                    <Text color="textSubtle">owners</Text>
                </Box>
                <Box>
                    <Heading scale="md" bold>
                        { dataFetchStatus === FetchStatus.PENDING ? (
                            <Skeleton height={ SKELETON_H2_HEIGHT } width={ 60 }/>
                        ) : (
                            <>
                                <EthereumIcon/> { getFullDisplayBN( collectionData.stats.floorPrice, 18, 2 ) }
                            </>
                        ) }
                    </Heading>
                    <Text color="textSubtle">floor price</Text>
                </Box>
                <Box>
                    { dataFetchStatus === FetchStatus.PENDING ? (
                        <Skeleton height={ SKELETON_H2_HEIGHT } width={ 40 }/>
                    ) : (
                        <Heading scale="md" bold>
                            { getFullDisplayBN( collectionData?.stats.totalVolume, 18, 2 ) }
                        </Heading>
                    ) }
                    <Text color="textSubtle">total volume</Text>
                </Box>
            </Wrapper>
        </PaddedRow>
    );
};

export default CollectionNumbers;