import { useCallback } from 'react';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { NoEthereumProviderError, UserRejectedRequestError as UserRejectedRequestErrorInjected } from '@web3-react/injected-connector';
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect, WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { setupNetwork } from 'utils/wallet';
import { connectorsByName } from 'utils/web3React';
import { connectorLocalStorageKey } from 'components/WalletModal/config';
import { ConnectorNames } from 'components/WalletModal/types';
import { removeAuthToken } from '../utils/authToken';

const useAuth = () => {
    const { activate, deactivate } = useWeb3React();

    const login = useCallback(
        ( connectorID: ConnectorNames ) => {
            const connector = connectorsByName[connectorID];
            if ( connector ) {
                activate( connector, async ( error: Error ) => {
                    if ( error instanceof UnsupportedChainIdError ) {
                        const hasSetup = await setupNetwork();
                        if ( hasSetup ) {
                            activate( connector );
                        }
                    } else {
                        window.localStorage.removeItem( connectorLocalStorageKey );
                        if ( error instanceof NoEthereumProviderError ) {
                            console.warn( 'No provider was found' );
                        } else if (
                            error instanceof UserRejectedRequestErrorInjected ||
                            error instanceof UserRejectedRequestErrorWalletConnect
                        ) {
                            if ( connector instanceof WalletConnectConnector ) {
                                const walletConnector = connector as WalletConnectConnector;
                                walletConnector.walletConnectProvider = null;
                            }
                            console.warn( 'Please authorize to access your account' );
                        } else {
                            console.warn( error.name, error.message );
                        }
                    }
                } );
            } else {
                console.warn( 'Unable to find connector', 'The connector config is wrong' );
            }
        },
        [ activate ],
    );

    const logout = useCallback( () => {
        // Remove Auth (JWT) Token
        removeAuthToken();

        // Deactivate the connector
        deactivate();

        // This localStorage key is set by @web3-react/walletconnect-connector
        if ( window.localStorage.getItem( 'walletconnect' ) ) {
            connectorsByName.walletconnect.close();
            connectorsByName.walletconnect.walletConnectProvider = null;
        }

        // Remove the connector from local storage
        window.localStorage.removeItem( connectorLocalStorageKey );
    }, [ deactivate ] );

    return { login, logout };
};

export default useAuth;
