import BigNumber from 'bignumber.js';
import { BIG_TEN } from './bigNumber';

/**
 * Take a formatted amount, e.g. 15 BNB and convert it to full decimal value, e.g. 15000000000000000
 * @param amount
 * @param decimals
 */
export const getDecimalAmount = ( amount: BigNumber, decimals = 18 ): BigNumber => {
    return new BigNumber( amount ).times( BIG_TEN.pow( decimals ) );
};

/**
 * Take a full decimal amount, e.g. 15000000000000000 and convert it to a formatted value, e.g. 15 BNB
 * @param amount
 * @param decimals
 */
export const getBalanceAmount = ( amount: BigNumber | string, decimals = 18 ): BigNumber => {
    if ( !amount ) {
        amount = '0';
    }

    return new BigNumber( amount ).dividedBy( BIG_TEN.pow( decimals ) );
};

/**
 * @param balance
 * @param decimals
 * @param displayDecimals
 */
export const getFullDisplayBN = ( balance: BigNumber | string, decimals = 18, displayDecimals = 2 ): string => {
    return formatNumber(
        getBalanceAmount( balance, decimals ).toNumber(),
        displayDecimals,
        displayDecimals,
    );
};

/**
 * @param number
 * @param minPrecision
 * @param maxPrecision
 */
export const formatNumber = ( number: number, minPrecision = 2, maxPrecision = 2 ): string => {
    return number.toLocaleString( undefined, {
        minimumFractionDigits: minPrecision,
        maximumFractionDigits: maxPrecision,
    } );
};

/**
 * @param number
 */
export const formatLocalisedCompactNumber = ( number: number ): string => {
    return new Intl.NumberFormat( 'en-US', {
        notation: 'compact',
        compactDisplay: 'short',
        useGrouping: true,
        minimumSignificantDigits: 3,
        maximumSignificantDigits: 3
    } ).format( number );
};

export const getRandomNumber = ( max = 10000 ) => Math.floor( Math.random() * max );
