import { MetamaskIcon, TrustWalletIcon, WalletConnectIcon } from 'components/Svg';
import { Config, ConnectorNames } from './types';

const connectors: Config[] = [
    {
        title: 'Metamask',
        icon: MetamaskIcon,
        connectorId: ConnectorNames.Injected,
        priority: 1,
    },
    {
        title: 'WalletConnect',
        icon: WalletConnectIcon,
        connectorId: ConnectorNames.WalletConnect,
        priority: 2,
    },
    {
        title: 'Trust Wallet',
        icon: TrustWalletIcon,
        connectorId: ConnectorNames.Injected,
        priority: 3,
    },
];

export default connectors;
export const connectorLocalStorageKey = 'marketplace-connector';
export const walletLocalStorageKey = 'marketplace-wallet';
