import styled from 'styled-components';
import { layout, space, typography } from 'styled-system';
import { TextareaProps } from './types';
import getThemeValue from '../../utils/getThemeValue';

const Textarea = styled.textarea<TextareaProps>`
    background-color: ${ ( { theme } ) => theme.colors.inputBackground };
    border: 0;
    border-radius: 4px;
    color: ${ ( { color, theme } ) => getThemeValue( `colors.${ color }`, color )( theme ) };
    display: inline-block;
    font-size: 16px;
    outline: 0;
    padding: 4px 16px;
    border: 1px solid ${ ( { theme } ) => theme.colors.inputBorder };
    line-height: 1.5;
    
    &::placeholder {
        color: ${ ( { theme } ) => theme.colors.textDisabled };
    }
    
    &:disabled {
        background-color: ${ ( { theme } ) => theme.colors.backgroundDisabled };
        box-shadow: none;
        color: ${ ( { theme } ) => theme.colors.textDisabled };
        cursor: not-allowed;
    }
    
    &:focus:not(:disabled) {
        border-color: ${ ( { theme } ) => theme.colors.inputFocusedBorder };
    }
    
    ${ space }
    ${ typography }
    ${ layout }
`;

export default Textarea;