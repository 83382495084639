import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledLink = styled( Link )`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem 0.75rem;
    
    & > svg {
        margin-right: 0.5rem;
    }
    
    &:hover {
        background-color: ${ ( { theme } ) => theme.colors.primary }22;
    }
`;

interface AccountDropdownMenuItemProps {
    label: string
    icon?: ReactElement
    href?: string
    onClick?: () => void
}

const AccountDropdownMenuItem: React.FC<AccountDropdownMenuItemProps> = ( props ) => {
    const {
        label,
        icon,
        href,
        onClick
    } = props;

    return (
        <StyledLink
            to={ href || '#' }
            onClick={ onClick }
        >
            { icon } { label }
        </StyledLink>
    );
};

export default AccountDropdownMenuItem;