import React from 'react';
import styled from 'styled-components';
import { IAsset } from 'config/constants/types';
import { GridLayout, Text } from 'components';
import Asset from './Asset';

const Wrapper = styled.div`

`;

interface CollectedItemsProps {
    assets?: IAsset[]
}

const CollectedItems: React.FC<CollectedItemsProps> = ( props ) => {
    const { assets } = props;

    return (
        <Wrapper>
            <Text color="textSubtle" my={ 3 }>{ assets?.length } items</Text>
            <GridLayout>
                { assets.map( ( collectionItem: IAsset ) => (
                    <Asset
                        key={ collectionItem.title }
                        item={ collectionItem }
                    />
                ) ) }
            </GridLayout>
        </Wrapper>
    );
};

export default CollectedItems;