import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { ConnectorNames } from 'components/WalletModal/types';
import { ethers } from 'ethers';
import getNodeUrl from './getRpcUrl';
import { DEFAULT_CHAIN_ID } from 'config/chain';

const POLLING_INTERVAL = 12000;
const rpcUrl = getNodeUrl();

const injected = new InjectedConnector( { supportedChainIds: [ DEFAULT_CHAIN_ID ] } );

const walletconnect = new WalletConnectConnector( {
    rpc: {
        [DEFAULT_CHAIN_ID]: rpcUrl
    },
    qrcode: true,
} );

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
    [ConnectorNames.Injected]: injected,
    [ConnectorNames.WalletConnect]: walletconnect,
};

export const getLibrary = ( provider: any ): ethers.providers.Web3Provider => {
    const library = new ethers.providers.Web3Provider( provider );
    library.pollingInterval = POLLING_INTERVAL;
    return library;
};

/**
 * BSC Wallet requires a different sign method
 * @see https://docs.binance.org/smart-chain/wallet/wallet_api.html#binancechainbnbsignaddress-string-message-string-promisepublickey-string-signature-string
 */
export const signMessage = async ( provider: any, account: string, message: string ): Promise<string> => {
    /**
     * Wallet Connect does not sign the message correctly unless you use their method
     * @see https://github.com/WalletConnect/walletconnect-monorepo/issues/462
     */
    if ( provider.provider?.wc ) {
        const wcMessage = ethers.utils.hexlify( ethers.utils.toUtf8Bytes( message ) );
        return provider.provider?.wc.signPersonalMessage( [ wcMessage, account ] );
    }

    return provider.getSigner( account ).signMessage( message );
};
