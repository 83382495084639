import React from 'react';
import { Accordion, AccordionSection, RefreshIcon, Table, TBody, Th, THead, Tr } from 'components';
import { ICollection } from 'config/constants/types';

export interface ItemActivityProps {
    collectionData?: ICollection
}

const ItemActivity: React.FC<ItemActivityProps> = () => {
    return (
        <Accordion my={ 4 }>
            <AccordionSection label="Item Activity" icon={ <RefreshIcon/> }>
                <Table>
                    <THead>
                        <Tr>
                            <Th>Event</Th>
                            <Th>Price</Th>
                            <Th>From</Th>
                            <Th>To</Th>
                            <Th>Date</Th>
                        </Tr>
                    </THead>
                    <TBody/>
                </Table>
            </AccordionSection>
        </Accordion>
    );
};

export default ItemActivity;