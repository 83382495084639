import React from 'react';
import { Accordion, AccordionSection, ListIcon, Skeleton, Table, TBody, Td, Th, THead, Tr } from 'components';
import { FetchStatus, IAsset } from 'config/constants/types';

interface AssetAttributesProps {
    asset?: IAsset
    assetDataFetchStatus: FetchStatus
}

const AssetAttributes: React.FC<AssetAttributesProps> = ( props ) => {
    const { asset, assetDataFetchStatus } = props;

    return (
        <Accordion>
            <AccordionSection label="Attributes" icon={ <ListIcon/> } expanded p={ 0 }>
                <Table width="100%">
                    <THead>
                        <Tr>
                            <Th align="left">Type</Th>
                            <Th align="left">Value</Th>
                            <Th align="left">Frequency</Th>
                        </Tr>
                    </THead>
                    <TBody>
                        { assetDataFetchStatus === FetchStatus.PENDING ? (
                            <Tr>
                                <Td><Skeleton width='5rem'/></Td>
                                <Td><Skeleton width='5rem'/></Td>
                                <Td><Skeleton width='5rem'/></Td>
                            </Tr>
                        ) : (
                            asset?.attributes?.map( attribute => (
                                <Tr key={ attribute.value } hoverEffect>
                                    <Td>{ attribute.trait_type }</Td>
                                    <Td>{ attribute.value }</Td>
                                    <Td>?</Td>
                                </Tr>
                            ) )
                        ) }
                    </TBody>
                </Table>
            </AccordionSection>
        </Accordion>
    );
};

export default AssetAttributes;