import { FC } from 'react';
import { SvgProps } from '../Svg';

export enum ConnectorNames {
    Injected = 'injected',
    WalletConnect = 'walletconnect',
}

export type Login = ( connectorId: ConnectorNames ) => void;

export interface Config {
    title: string;
    icon: FC<SvgProps>;
    connectorId: ConnectorNames;
    priority: number;
}
