import { SpaceProps } from 'styled-system';
import { HTMLInputTypeAttribute } from 'react';

export const scales = {
    SM: 'sm',
    MD: 'md',
    LG: 'lg',
} as const;

export type Scales = typeof scales[keyof typeof scales];

export interface InputProps extends SpaceProps {
    type?: HTMLInputTypeAttribute
    scale?: Scales;
    isSuccess?: boolean;
    isWarning?: boolean;
}