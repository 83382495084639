import React from 'react';
import { AccordionSection, CheckboxesIcon, Link, Skeleton } from 'components';
import SpacedBetweenRow from './SpacedBetweenRow';
import { FetchStatus, IAsset, ICollection } from 'config/constants/types';
import { truncateAddress } from 'utils/addressHelpers';
import { COLLECTION_BASE_URL } from '../../Collection/types';

export interface CollectionDetailsSectionProps {
    collectionData?: ICollection
    collectionDataFetchStatus: FetchStatus
    assetData?: IAsset
    assetDataFetchStatus: FetchStatus
}

const CollectionDetailsSection: React.FC<CollectionDetailsSectionProps> = ( props ) => {
    const { collectionData, collectionDataFetchStatus, assetData, assetDataFetchStatus } = props;

    return (
        <AccordionSection label="Details" icon={ <CheckboxesIcon/> }>
            <SpacedBetweenRow>
                <span>Contract address:</span>
                { collectionDataFetchStatus === FetchStatus.PENDING ? (
                    <Skeleton width="10rem"/>
                ) : (
                    <Link href={ `${ COLLECTION_BASE_URL }/${ collectionData?.contract_address }` }>
                        { truncateAddress( collectionData?.contract_address ) }
                    </Link>
                ) }
            </SpacedBetweenRow>

            <SpacedBetweenRow>
                <span>Token ID:</span>
                { assetDataFetchStatus === FetchStatus.PENDING ? (
                    <Skeleton width="10rem"/>
                ) : (
                    <span>{ assetData?.asset_id }</span>
                ) }
            </SpacedBetweenRow>

            <SpacedBetweenRow>
                <span>Chain ID:</span>
                { collectionDataFetchStatus === FetchStatus.PENDING ? (
                    <Skeleton width="10rem"/>
                ) : (
                    <span>{ collectionData?.chain_id }</span>
                ) }
            </SpacedBetweenRow>

            <SpacedBetweenRow>
                <span>Creator fees:</span>
                { collectionDataFetchStatus === FetchStatus.PENDING ? (
                    <Skeleton width="10rem"/>
                ) : (
                    <span>{ collectionData?.creatorFees ? `${ collectionData?.creatorFees }%` : 'Not available' }</span>
                ) }
            </SpacedBetweenRow>
        </AccordionSection>
    );
};

export default CollectionDetailsSection;