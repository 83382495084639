import React from 'react';
import styled from 'styled-components';
import { PaddedRow, Skeleton } from 'components';
import { FetchStatus, ICollection } from '../../../config/constants/types';

const BannerWrapper = styled.div`
    background: #EEE;
    width: 100%;
    height: 18rem;
    position: relative;
    overflow: hidden;
    inset: 0;
    margin: 0;
`;

const LogoWrapper = styled.div`
    width: 180px;
    height: 180px;
    position: relative;
    margin-top: -156px;
    background-color: white;
    border: 6px solid white;
    border-radius: 16px;
    overflow: hidden;
`;

const FitImage = styled.img`
    position: absolute;
    inset: 0;
    box-sizing: border-box;
    padding: 0;
    border: none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
`;

export interface CollectionBannerLogoProps {
    collectionData?: ICollection
    dataFetchStatus: FetchStatus
}

// TODO: Find a better name for this component.

const CollectionBannerLogo: React.FC<CollectionBannerLogoProps> = ( props ) => {
    const { collectionData, dataFetchStatus } = props;

    return (
        <>
            <BannerWrapper>
                { dataFetchStatus === FetchStatus.PENDING ? (
                    <Skeleton height="100%" width="100%"/>
                ) : (
                    <FitImage src={ collectionData?.bannerImage } alt=""/>
                ) }
            </BannerWrapper>

            <PaddedRow>
                <LogoWrapper>
                    { dataFetchStatus === FetchStatus.PENDING ? (
                        <Skeleton height="100%" width="100%"/>
                    ) : (
                        <FitImage src={ collectionData?.logoImage } alt=""/>
                    ) }
                </LogoWrapper>
            </PaddedRow>
        </>
    );
};

export default CollectionBannerLogo;