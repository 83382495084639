import styled from 'styled-components';
import Grid from '../Box/Grid';

const GridLayout = styled( Grid )`
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 16px;
  ${ ( { theme } ) => theme.mediaQueries.sm } {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 4px;
  }
  ${ ( { theme } ) => theme.mediaQueries.md } {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 12px;
  }
  ${ ( { theme } ) => theme.mediaQueries.lg } {
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 16px;
  }
`;

export default GridLayout;
