import React from 'react';
import { Route, useParams } from 'react-router-dom';
import accountData from 'config/temp/account.json';
import { ACCOUNT_ACTIVITY_URI, ACCOUNT_BASE_URL, ACCOUNT_COLLECTED_URI, ACCOUNT_CREATIONS_URI, ACCOUNT_FAVORITES_URI, ACCOUNT_SETTINGS_URI } from './types';
import CollectedPage from './pages/CollectedPage';
import CreationsPage from './pages/CreationsPage';
import FavoritesPage from './pages/FavoritesPage';
import ActivitiesPage from './pages/ActivitiesPage';
import SettingsPage from './pages/SettingsPage';

const Account: React.FC = () => {
    const { walletAddress, activeTab } = useParams<{ walletAddress: string, activeTab?: string }>();

    const urlPrefix = `${ ACCOUNT_BASE_URL }/${ walletAddress }`;

    return (
        <>
            <Route exact path={ [ `${ urlPrefix }/`, `${ urlPrefix }/${ ACCOUNT_COLLECTED_URI }` ] }>
                <CollectedPage activeTab={ activeTab } account={ accountData }/>
            </Route>

            <Route exact path={ `${ urlPrefix }/${ ACCOUNT_CREATIONS_URI }` }>
                <CreationsPage activeTab={ activeTab } account={ accountData }/>
            </Route>

            <Route exact path={ `${ urlPrefix }/${ ACCOUNT_FAVORITES_URI }` }>
                <FavoritesPage activeTab={ activeTab } account={ accountData }/>
            </Route>

            <Route exact path={ `${ urlPrefix }/${ ACCOUNT_ACTIVITY_URI }` }>
                <ActivitiesPage activeTab={ activeTab } account={ accountData }/>
            </Route>

            <Route exact path={ `${ urlPrefix }/${ ACCOUNT_SETTINGS_URI }` }>
                <SettingsPage account={ accountData }/>
            </Route>
        </>
    );
};

export default Account;
