import React from 'react';
import Svg from '../Svg';
import { SvgProps } from '../types';

const Icon: React.FC<SvgProps> = ( props ) => {
    return (
        <Svg viewBox="0 0 60 60" { ...props }>
            <path d="M0,0.5v59h60v-59H0z M20,51.5H10v-10h10V51.5z M20,35.5H10v-10h10V35.5z M20,19.5H10v-10h10V19.5z M48,47.5H25
	c-0.552,0-1-0.447-1-1s0.448-1,1-1h23c0.552,0,1,0.447,1,1S48.552,47.5,48,47.5z M48,31.5H25c-0.552,0-1-0.447-1-1s0.448-1,1-1h23
	c0.552,0,1,0.447,1,1S48.552,31.5,48,31.5z M48,15.5H25c-0.552,0-1-0.447-1-1s0.448-1,1-1h23c0.552,0,1,0.447,1,1
	S48.552,15.5,48,15.5z"/>
        </Svg>
    );
};

export default Icon;
