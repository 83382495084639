import styled, { DefaultTheme } from 'styled-components';
import { InputProps, scales } from './types';

interface StyledInputProps extends InputProps {
    theme: DefaultTheme;
}

/**
 * Priority: Warning --> Success
 */
const getBoxShadow = ( { isSuccess = false, isWarning = false }: StyledInputProps ) => {
    if ( isWarning ) {
        return '0px 0px 0px 1px #ED4B9E, 0px 0px 0px 4px rgba(237, 75, 158, 0.2)';
    }

    if ( isSuccess ) {
        return '0px 0px 0px 1px #31D0AA, 0px 0px 0px 4px rgba(49, 208, 170, 0.2)';
    }

    return 'inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)';
};

const getHeight = ( { scale = scales.MD }: StyledInputProps ) => {
    switch ( scale ) {
        case scales.SM:
            return '32px';
        case scales.LG:
            return '48px';
        case scales.MD:
        default:
            return '40px';
    }
};

const Input = styled.input<InputProps>`
  background-color: ${ ( { theme } ) => theme.colors.inputBackground };
  border: 0;
  border-radius: 4px;
  box-shadow: ${ getBoxShadow };
  color: #333;
  display: inline-block;
  font-size: 16px;
  height: ${ getHeight };
  outline: 0;
  padding: 0 16px;
  border: 1px solid ${ ( { theme } ) => theme.colors.inputBorder };

  &::placeholder {
    color: ${ ( { theme } ) => theme.colors.textDisabled };
  }

  &:disabled {
    background-color: ${ ( { theme } ) => theme.colors.backgroundDisabled };
    box-shadow: none;
    color: ${ ( { theme } ) => theme.colors.textDisabled };
    cursor: not-allowed;
  }

  &:focus:not(:disabled) {
    border-color: ${ ( { theme } ) => theme.colors.inputFocusedBorder };
  }
`;

Input.defaultProps = {
    scale: scales.MD,
    isSuccess: false,
    isWarning: false,
};

export default Input;
