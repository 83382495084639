import { useCallback } from 'react';
import ethers, { CallOverrides, Contract } from 'ethers';
import { get } from 'lodash';
import getGasPrice from 'utils/getGasPrice';
import { DEFAULT_GAS_LIMIT } from 'config/constants/gas';

/**
 * Perform a contract call with a gas price returned from useGasPrice
 * @returns https://docs.ethers.io/v5/api/providers/types/#providers-TransactionReceipt
 */
export function useCallWithGasPrice() {
    const gasPrice = getGasPrice();

    const callWithGasPrice = useCallback(
        async (
            contract: Contract,
            methodName: string,
            methodArgs: any[] = [],
            overrides?: CallOverrides
        ): Promise<ethers.providers.TransactionResponse> => {
            const contractMethod = get( contract, methodName );

            return await contractMethod(
                ...methodArgs,
                {
                    gasPrice,
                    gasLimit: DEFAULT_GAS_LIMIT,
                    ...overrides,
                },
            );
        },
        [ gasPrice ],
    );

    return { callWithGasPrice };
}
