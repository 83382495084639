import React from 'react';
import styled from 'styled-components';
import { ModalProps } from './types';
import { IconButton } from '../Button';
import { CloseIcon } from 'components/Svg';

export const ModalHeader = styled.div<{ background?: string }>`
  align-items: center;
  background: linear-gradient(190deg, #762dad 0%, #512d6d 100%);
  color: white;
  display: flex;
  padding: 12px 24px;
`;

export const ModalTitle = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  overflow-y: auto;
  padding: 24px;
`;

export const ModalCloseButton: React.FC<{ onDismiss: ModalProps['onDismiss'] }> = ( { onDismiss } ) => {
    return (
        <IconButton variant="text" onClick={ onDismiss } aria-label="Close the dialog">
            <CloseIcon color="white"/>
        </IconButton>
    );
};

export const ModalBackButton: React.FC<{ onBack: ModalProps['onBack'] }> = ( { onBack } ) => {
    return (
        <button onClick={ onBack } aria-label="go back">
            Back
        </button>
    );
};

export const ModalContainer = styled.div<{ minWidth: string }>`
  overflow: hidden;
  background: ${ ( { theme } ) => theme.modal.background };
  border: 1px solid #666;
  border-radius: 0px;
  width: 100%;
  max-height: 100vh;
  z-index: ${ ( { theme } ) => theme.zIndices.modal };

  ${ ( { theme } ) => theme.mediaQueries.xs } {
    width: auto;
    min-width: ${ ( { minWidth } ) => minWidth };
    max-width: 100%;
  }
`;
