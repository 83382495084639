import React from 'react';
import styled from 'styled-components';
import BigNumber from 'bignumber.js';
import { CloseIcon, EthereumIcon, Flex, Heading, LoadingIcon, Text } from 'components';
import useEthUsdPrice from 'hooks/useEthUsdPrice';
import { FetchStatus } from 'config/constants/types';
import { formatNumber } from 'utils/formatBalance';
import { BIG_ZERO } from 'utils/bigNumber';

interface EthUsdPriceProps {
    ethValue?: BigNumber
    scale?: 'sm' | 'md'
}

const ContentWrapper = styled( Flex )`
    align-items: center;
`;

/**
 * TODO: Update price every 30s.
 */

const EthUsdPrice: React.FC<EthUsdPriceProps> = ( props ) => {
    const { ethValue, scale } = props;

    const { ethUsdPrice, fetchStatus } = useEthUsdPrice();

    return (
        <ContentWrapper>
            <EthereumIcon/>
            <div>
                <Heading scale={ scale } mr={ 2 }>
                    { ethValue.toFixed( 3 ) }
                </Heading>
                { fetchStatus === FetchStatus.PENDING && <LoadingIcon/> }
                { fetchStatus === FetchStatus.FAILED && <CloseIcon/> }
                { fetchStatus === FetchStatus.SUCCESS && (
                    <Text color="textSubtle" small>
                        ${ formatNumber( ethValue?.times( ethUsdPrice ).toNumber() ) }
                    </Text>
                ) }
            </div>
        </ContentWrapper>
    );
};

EthUsdPrice.defaultProps = {
    ethValue: BIG_ZERO,
    scale: 'md'
};

export default EthUsdPrice;