import { useMemo } from 'react';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { getErc20Contract, getMarketplaceContract } from 'utils/contractHelpers';

export const useERC20 = ( address: string ) => {
    const { library } = useActiveWeb3React();
    return useMemo( () => getErc20Contract( address, library?.getSigner() ), [ address, library ] );
};

export const useMarketplaceContract = () => {
    const { library } = useActiveWeb3React();
    return useMemo( () => getMarketplaceContract( library?.getSigner() ), [ library ] );
};
