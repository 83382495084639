import { ethers } from 'ethers';
import { simpleRpcProvider } from 'utils/providers';

import contracts from 'config/constants/contracts';

import erc20Abi from 'config/abi/erc20.json';
import marketplaceAbi from 'config/abi/marketplace.json';
import { getAddress } from './addressHelpers';

const getContract = ( abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider ) => {
    const signerOrProvider = signer ?? simpleRpcProvider;
    return new ethers.Contract( address, abi, signerOrProvider );
};

export const getErc20Contract = ( address: string, signer?: ethers.Signer | ethers.providers.Provider ) => {
    return getContract( erc20Abi, address, signer );
};

export const getMarketplaceContract = ( signer?: ethers.Signer | ethers.providers.Provider ) => {
    return getContract( marketplaceAbi, getAddress( contracts.marketplace ), signer );
};