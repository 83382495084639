import React from 'react';
import { Accordion, AccordionSection } from '../../../components/Accordion';
import { LineChartIcon } from '../../../components/Svg';

const AssetPriceHistory: React.FC = () => {
    return (
        <Accordion mt={ 4 }>
            <AccordionSection label="Price History" icon={ <LineChartIcon/> }>
                N/A
            </AccordionSection>
        </Accordion>
    );
};

export default AssetPriceHistory;