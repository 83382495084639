import React from 'react';
import useAuth from 'hooks/useAuth';
import styled from 'styled-components';
import useWalletModal from '../WalletModal/useWalletModal';
import WalletButton from './WalletButton';

const ConnectButton = styled( WalletButton )`
    background-color: rgba(255, 255, 255, .8);
    border: 2px solid ${ ( { theme } ) => theme.colors.primary };
    color: ${ ( { theme } ) => theme.colors.primary };
    
    &:hover {
        background: #ffffff;
    }
`;

const ConnectWalletButton = ( props: any ) => {
    const { login, logout } = useAuth();
    const { onPresentConnectModal } = useWalletModal( login, logout );

    return (
        <ConnectButton onClick={ onPresentConnectModal } { ...props }>
            Connect Wallet
        </ConnectButton>
    );
};

export default ConnectWalletButton;
