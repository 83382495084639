import React from 'react';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

const Wrapper = styled.div`
    padding: 0 4rem;
    ${ space }
`;

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface PaddedRowProps extends SpaceProps {
}

export const PaddedRow: React.FC<PaddedRowProps> = ( props ) => {
    const { children, ...otherProps } = props;
    return (
        <Wrapper { ...otherProps }>{ children }</Wrapper>
    );
};