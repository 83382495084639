import React from 'react';
import styled from 'styled-components';
import { HrProps } from './types';
import { space } from 'styled-system';

const StyledHr = styled.hr`
    display: block;
    width: 100%;
    height: 1px;
    border-top: 1px solid ${ ( { theme } ) => theme.colors.cardBorderSubtle };
    border-right: 0px none;
    border-bottom: 0px none;
    border-left: 0px none;
    ${ space }
`;

const Hr: React.FC<HrProps> = ( props ) => {
    return <StyledHr { ...props }/>;
};

export default Hr;