import React from 'react';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';
import { Accordion, AccordionSection, Box, Button, Checkbox, Flex, Heading, Input, InputLabel, Radio, Text } from 'components';
import { ICollection, ICollectionAttribute, ICollectionAttributes } from 'config/constants/types';
import collectionAttributes from 'config/temp/collectionAttributes.json';
import { ucFirst } from '../../../utils/stringHelpers';

interface CollectionFiltersProps extends SpaceProps {
    collection: ICollection
}

const StyledAccordion = styled( Accordion )`
    width: 15rem;
    border: 0px none;
    ${ space }
`;

const QuantityInput = styled( Input )`
    max-width: 5rem;
    text-align: center;
`;

const CollectionFilters: React.FC<CollectionFiltersProps> = ( props ) => {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { collection, ...otherProps } = props;

    const defaultPropsForInputLabel = {
        p: 2,
        hoverEffect: true,
    };

    return (
        <Box>
            <StyledAccordion borderedSections={ false } { ...otherProps }>
                <AccordionSection label="Status">
                    <InputLabel label="Buy Now" { ...defaultPropsForInputLabel } mb={ 2 }>
                        <Radio name="quantity" value="buyNow"/>
                    </InputLabel>
                    <InputLabel label="On Auction" { ...defaultPropsForInputLabel }>
                        <Radio name="quantity" value="onAuction"/>
                    </InputLabel>
                </AccordionSection>

                <AccordionSection label="Price">
                    <Flex alignItems="center" justifyContent="flex-start">
                        <QuantityInput placeholder="Min"/>
                        <Text mx={ 2 }>to</Text>
                        <QuantityInput placeholder="Max"/>
                    </Flex>
                    <Flex justifyContent="center" mt={ 2 }>
                        <Button scale="sm">Apply</Button>
                    </Flex>
                </AccordionSection>

                <AccordionSection label="Quantity">
                    <InputLabel label="All Items" { ...defaultPropsForInputLabel }>
                        <Radio name="quantity" value="allItems"/>
                    </InputLabel>
                    <InputLabel label="Single Items" { ...defaultPropsForInputLabel }>
                        <Radio name="quantity" value="singleItems"/>
                    </InputLabel>
                    <InputLabel label="Bundle" { ...defaultPropsForInputLabel }>
                        <Radio name="quantity" value="bundle"/>
                    </InputLabel>
                </AccordionSection>

                <AccordionSection label="Currency">
                    <InputLabel label="ETH" { ...defaultPropsForInputLabel }>
                        <Checkbox name="currency" value="ETH"/>
                    </InputLabel>
                    <InputLabel label="WETH" { ...defaultPropsForInputLabel }>
                        <Checkbox name="currency" value="WETH"/>
                    </InputLabel>
                </AccordionSection>
            </StyledAccordion>

            <Box p={ 3 } mt={ 3 }>
                <Heading scale="md">Properties</Heading>
            </Box>

            <StyledAccordion borderedSections={ false } { ...otherProps }>
                { Object.entries( collectionAttributes as ICollectionAttributes )
                        .map( ( group ) => {
                            const [ groupName, attributes ] = group;

                            return (
                                <AccordionSection
                                    key={ groupName }
                                    label={ ucFirst( groupName ) }
                                    secondaryLabel={ `${ attributes.length }` }
                                >
                                    { attributes.map( ( attribute: ICollectionAttribute ) => (
                                        <InputLabel
                                            key={ attribute.label }
                                            label={ attribute.label }
                                            { ...defaultPropsForInputLabel }
                                        >
                                            <Checkbox name="attribute" value={ attribute.label }/>
                                        </InputLabel>
                                    ) ) }
                                </AccordionSection>
                            );
                        } ) }
            </StyledAccordion>
        </Box>
    );
};

export default CollectionFilters;