import { useEffect, useState } from 'react';
import { FetchStatus, IAsset } from 'config/constants/types';
import useFetchFromApi from 'hooks/useFetchFromApi';

const useAssetData = ( collectionContract: string, assetId: string ) => {
    const { fetchDataFromApi } = useFetchFromApi();

    const [ assetData, setAssetData ] = useState<IAsset | undefined>( undefined );
    const [ fetchStatus, setFetchStatus ] = useState<FetchStatus>( FetchStatus.PENDING );

    useEffect( () => {

        const fetchCollectionData = async () => {
            try {
                const response = await fetchDataFromApi( `/asset/${ collectionContract }/${ assetId }` );
                setAssetData( response as IAsset );
                setFetchStatus( FetchStatus.SUCCESS );
            } catch ( err ) {
                setFetchStatus( FetchStatus.FAILED );
                throw err;
            }
        };

        fetchCollectionData().catch( err => console.warn( 'Error fetching asset data:', err.message ) );
    }, [
        fetchDataFromApi,
        collectionContract
    ] );

    return {
        assetData,
        fetchStatus
    };
};


export default useAssetData;