import React from 'react';
import { GridLayout } from 'components';
import { FetchStatus, ICollection } from 'config/constants/types';
import Collection from './Collection';
import useRandomCollections from '../hooks/useRandomCollections';
import CollectionSkeleton from './CollectionSkeleton';

interface RandomCollectionsProps {
    activeTab: string
}

const RandomCollections: React.FC<RandomCollectionsProps> = ( props ) => {
    const { activeTab } = props;
    const { collections, fetchStatus } = useRandomCollections( activeTab );

    if ( fetchStatus === FetchStatus.PENDING ) {
        return (
            <GridLayout>
                { [ 1, 2, 3, 4, 5 ].map( ( arg ) =>
                    <CollectionSkeleton key={ arg }/>
                ) }
            </GridLayout>
        );
    }

    if ( fetchStatus === FetchStatus.FAILED ) {
        return (
            <div>
                Failed loading collections
            </div>
        );
    }

    if ( collections.length <= 0 ) {
        return (
            <div>
                No collections found in this category
            </div>
        );
    }

    return (
        <GridLayout>
            { collections.map( ( collection: ICollection ) => {
                return (
                    <Collection
                        key={ collection.title }
                        collection={ collection }
                    />
                );
            } ) }
        </GridLayout>
    );
};

export default RandomCollections;