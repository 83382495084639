import React from 'react';
import { Web3ReactProvider } from '@web3-react/core';
import { ThemeProvider } from 'styled-components';
import { getLibrary } from 'utils/web3React';
import { ModalProvider } from './components/Modal';
import { appTheme } from './config/theme';
import { HelmetProvider } from 'react-helmet-async';

const Providers: React.FC = ( { children } ) => {
    return (
        <Web3ReactProvider getLibrary={ getLibrary }>
            <ThemeProvider theme={ appTheme }>
                <HelmetProvider>
                    <ModalProvider>{ children }</ModalProvider>
                </HelmetProvider>
            </ThemeProvider>
        </Web3ReactProvider>
    );
};

export default Providers;
