import React from 'react';
import { Modal } from '../Modal';
import { connectorLocalStorageKey } from './config';

interface Props {
    account: string;
    logout: () => void;
    onDismiss?: () => void;
}

const AccountModal: React.FC<Props> = ( { account, logout, onDismiss = () => null } ) => (
    <Modal title={ 'Your wallet' } onDismiss={ onDismiss }>
        <div>
            { account }
        </div>
        <button
            onClick={ () => {
                logout();
                window.localStorage.removeItem( connectorLocalStorageKey );
                onDismiss && onDismiss();
            } }
        >
            Logout
        </button>
    </Modal>
);

export default AccountModal;
