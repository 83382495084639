import React from 'react';
import useAuth from 'hooks/useAuth';
import { useWeb3React } from '@web3-react/core';
import styled from 'styled-components';
import { truncateAddress } from '../../utils/addressHelpers';
import AccountDropdownMenuItem from './AccountDropdownMenuItem';
import { CogIcon, EyeIcon, GridIcon, HeartStrokedIcon, SplitVerticalIcon, UserIcon } from '../Svg';
import { ACCOUNT_BASE_URL, ACCOUNT_COLLECTED_URI, ACCOUNT_CREATIONS_URI, ACCOUNT_FAVORITES_URI, ACCOUNT_SETTINGS_URI } from '../../views/Account/types';

const Wrapper = styled.div`
    position: relative;
`;

const buttonHoverBgColor = 'rgba(255, 255, 255, 1)';
const Button = styled.button<{ isActive?: boolean }>`
    position: relative;
    width: 8rem;
    height: 2.5rem;
    background-color: ${ ( { isActive } ) => isActive ? buttonHoverBgColor : 'rgba(255, 255, 255, .8)' };
    border: 0px none;
    color: ${ ( { theme } ) => theme.colors.primary };
    cursor: pointer;
    
    &:hover {
        background: ${ buttonHoverBgColor };
    }
`;

const MenuWrapper = styled.div`
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 9999;
    width: 13rem;
    overflow: hidden;
    text-align: left;
    background: white;
    border-radius: 1rem 0 1rem 1rem;
    border: 1px solid ${ ( { theme } ) => theme.colors.cardBorderSubtle };
    border-top: 0px none !important;
`;

const AccountDropdown = () => {
    const { account } = useWeb3React();
    const { logout } = useAuth();

    const menuRef = React.useRef<HTMLDivElement | null>( null );
    const [ menuVisible, setMenuVisible ] = React.useState( false );

    const showMenu = () => {
        setMenuVisible( true );
    };

    const closeMenu = () => {
        setMenuVisible( false );
        document.removeEventListener( 'click', closeMenu );
    };

    const handleLogout = () => {
        if ( !confirm( 'Are you sure?' ) ) {
            return;
        }
        logout();
    };

    React.useEffect( () => {
        const handleClickOutsideDialog = ( event: any ) => {
            if (
                menuRef &&
                !menuRef?.current?.contains( event.target as Node )
            ) {
                closeMenu();
            }
        };

        document.addEventListener( 'mousedown', handleClickOutsideDialog );

        return () => {
            document.removeEventListener( 'mousedown', handleClickOutsideDialog );
        };
    }, [
        closeMenu
    ] );

    return account ? (
        <Wrapper>
            <Button
                isActive={ menuVisible }
                onClick={ showMenu }>
                { truncateAddress( account, 6, 4 ) }
            </Button>

            { menuVisible && (
                <MenuWrapper ref={ menuRef }>
                    <AccountDropdownMenuItem
                        label="Profile"
                        icon={ <UserIcon/> }
                        href={ `${ ACCOUNT_BASE_URL }/${ account }/${ ACCOUNT_COLLECTED_URI }` }
                    />
                    <AccountDropdownMenuItem
                        label="Favorites"
                        icon={ <HeartStrokedIcon/> }
                        href={ `${ ACCOUNT_BASE_URL }/${ account }/${ ACCOUNT_FAVORITES_URI }` }
                    />
                    <AccountDropdownMenuItem
                        label="Watchlist"
                        icon={ <EyeIcon/> }
                        href={ `${ ACCOUNT_BASE_URL }/${ account }/${ ACCOUNT_FAVORITES_URI }` }
                    />
                    <AccountDropdownMenuItem
                        label="My Collections"
                        icon={ <GridIcon/> }
                        href={ `${ ACCOUNT_BASE_URL }/${ account }/${ ACCOUNT_CREATIONS_URI }` }
                    />
                    <AccountDropdownMenuItem
                        label="Settings"
                        icon={ <CogIcon/> }
                        href={ `${ ACCOUNT_BASE_URL }/${ account }/${ ACCOUNT_SETTINGS_URI }` }
                    />
                    <AccountDropdownMenuItem
                        label="Logout"
                        icon={ <SplitVerticalIcon/> }
                        onClick={ handleLogout }
                    />
                </MenuWrapper>
            ) }
        </Wrapper>
    ) : null;
};

export default AccountDropdown;
