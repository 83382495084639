import React from 'react';
import styled from 'styled-components';
import { PaddedRow } from 'components';
import { IAccount } from 'config/constants/types';

const BannerWrapper = styled.div`
    background: #EEE;
    width: 100%;
    height: 18rem;
    position: relative;
    overflow: hidden;
    inset: 0;
    margin: 0;
`;

const ProfileImageWrapper = styled.div`
    width: 180px;
    height: 180px;
    position: relative;
    margin-top: -156px;
    background-color: white;
    border: 6px solid white;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: rgb(0 0 0 / 8%) 0px 5px 20px 0px;
`;

const FitImage = styled.img`
    position: absolute;
    inset: 0;
    box-sizing: border-box;
    padding: 0;
    border: none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
`;

export interface ProfileBannerProps {
    account: IAccount
}

const ProfileBanner: React.FC<ProfileBannerProps> = ( props ) => {
    const { account } = props;

    return (
        <>
            <BannerWrapper>
                <FitImage src={ account?.bannerImage } alt=""/>
            </BannerWrapper>

            <PaddedRow>
                <ProfileImageWrapper>
                    <FitImage src={ account.profileImage } alt=""/>
                </ProfileImageWrapper>
            </PaddedRow>
        </>
    );
};

export default ProfileBanner;