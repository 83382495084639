import { useCallback, useState } from 'react';
import { FetchStatus, IAsset } from 'config/constants/types';
import useFetchFromApi from 'hooks/useFetchFromApi';

const useMoreFromCollection = ( collectionContract: string, assetId: string, quantity = 5 ) => {
    const { fetchDataFromApi } = useFetchFromApi();

    const [ assets, setAssets ] = useState<IAsset[]>( [] );
    const [ fetchStatus, setFetchStatus ] = useState<FetchStatus>( FetchStatus.PENDING );

    const fetchAssets = useCallback( async () => {
        try {
            const response = await fetchDataFromApi( `/collection/${ collectionContract }/more/${ assetId }?quantity=${ quantity }` );

            setAssets( response as IAsset[] );
            setFetchStatus( FetchStatus.SUCCESS );
        } catch ( err ) {
            setFetchStatus( FetchStatus.FAILED );
            throw err;
        }
    }, [
        fetchDataFromApi,
        collectionContract,
        assetId,
        quantity,
    ] );

    return {
        assets,
        fetchAssets,
        fetchStatus,
    };
};


export default useMoreFromCollection;