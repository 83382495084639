import styled from 'styled-components';
import { space } from 'styled-system';
import { Td } from './Cell';
import { TBody } from './TBody';

const Table = styled.table`
    max-width: 100%;
    width: 100%;
    background-color: ${ ( { theme } ) => theme.colors.background };
    
    ${ TBody } tr:last-child {
        ${ Td } {
            border-bottom: 0;
        }
    }
    
    ${ space }
`;

export default Table;
