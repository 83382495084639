import React from 'react';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

export interface AccordionProps extends SpaceProps {
    bordered?: boolean
    borderedSections?: boolean
}

const AccordionWrapper = styled.div<{ bordered?: boolean, borderedSections?: boolean }>`
    overflow: hidden;

    ${ ( { bordered, theme } ) => bordered && `
        border: 1px solid ${ theme.colors.cardBorder };
        border-radius: 16px;
    ` };
    
    ${ ( { borderedSections, theme } ) => borderedSections && `
        & > div {
            border-bottom: 1px solid ${ theme.colors.cardBorderSubtle };
            
            &:last-child {
                border-bottom: 0px none;
            }
        }
    ` };
    
    ${ space }
`;

const Accordion: React.FC<AccordionProps> = ( props ) => {
    const { children, ...otherProps } = props;

    return (
        <AccordionWrapper { ...otherProps }>
            { children }
        </AccordionWrapper>
    );
};

AccordionWrapper.defaultProps = {
    bordered: true,
    borderedSections: true
};

export default Accordion;