import { Colors } from './types';

export const baseColors = {
    primary: '#7B4F93',
    secondary: '#8d7898',
    tertiary: '#723149',
};

export const alertsColors = {
    success: '#31D0AA',
    warning: '#FFB237',
    failure: '#ed4b4b',
};

export const appColors: Colors = {
    ...baseColors,
    ...alertsColors,
    background: '#F8F9FA',
    backgroundDisabled: '#ebeeee',
    text: '#181513',
    textDisabled: '#c0cacd',
    textSubtle: '#707a83',
    cardBorder: '#ccc',
    cardBorderSubtle: '#ddd',
    inputBackground: '#FEFEFE',
    inputBorder: '#ddd',
    inputHoverBackground: '#EFEFEF',
    inputFocusedBorder: '#CACACA',
};