import React from 'react';
import { AccordionSection, Skeleton, SplitVerticalIcon } from 'components';
import { FetchStatus, ICollection } from 'config/constants/types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { COLLECTION_BASE_URL } from '../../Collection/types';

export interface CollectionAboutSection {
    collectionData?: ICollection
    collectionDataFetchStatus: FetchStatus
}

const StyledLink = styled( Link )`
    color: ${ ( { theme } ) => theme.colors.primary };
    font-weight: bold;
    display: block;
    margin-top: 1rem;
`;

const CollectionAboutSection: React.FC<CollectionAboutSection> = ( props ) => {
    const { collectionData, collectionDataFetchStatus } = props;

    return (
        <AccordionSection label='About this collection' icon={ <SplitVerticalIcon/> } expanded>
            { collectionDataFetchStatus === FetchStatus.PENDING ? (
                <>
                    <Skeleton width="100%" height={ 25 }/>
                    <Skeleton width="100%" height={ 25 } mt={ 1 }/>
                </>
            ) : (
                <>
                    <p>{ collectionData?.description }</p>
                    <StyledLink to={ `${ COLLECTION_BASE_URL }/${ collectionData?.contract_address }` }>
                        View all items
                    </StyledLink>
                </>
            ) }

        </AccordionSection>
    );
};

export default CollectionAboutSection;