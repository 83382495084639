import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import ResetCSS from 'style/ResetCSS';
import GlobalStyle from 'style/Global';

import { ACCOUNT_BASE_URL } from './views/Account/types';
import { ASSET_BASE_URL } from './views/Asset/types';
import { COLLECTION_BASE_URL } from './views/Collection/types';
import { EXPLORE_BASE_URL } from './views/Explore/types';

import Account from './views/Account';
import Asset from './views/Asset';
import Collection from './views/Collection';
import Explore from './views/Explore';
import NotFound from 'views/NotFound';

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <ResetCSS/>
            <GlobalStyle/>
            <Toaster/>
            <Switch>
                {/* Explore */ }
                <Route path={ `${ EXPLORE_BASE_URL }/:activeTab?` }>
                    <Explore/>
                </Route>

                {/* Collection */ }
                <Route path={ `${ COLLECTION_BASE_URL }/:slug` }>
                    <Collection/>
                </Route>

                {/* Asset */ }
                <Route path={ `${ ASSET_BASE_URL }/:collectionSlug/:assetId` }>
                    <Asset/>
                </Route>

                {/* Account */ }
                <Route path={ `${ ACCOUNT_BASE_URL }/:walletAddress/:activeTab?` }>
                    <Account/>
                </Route>

                {/* Redirects */ }
                <Route path="/" exact>
                    <Redirect to={ `${ EXPLORE_BASE_URL }` }/>
                </Route>

                {/* 404 */ }
                <Route component={ NotFound }/>
            </Switch>
        </BrowserRouter>
    );
};

export default React.memo( App );
