import React from 'react';
import { PaddedRow, Page, PageHeader, Text } from 'components';
import { IAccount } from 'config/constants/types';
import ProfileHeader from '../components/ProfileHeader';
import ProfileBanner from '../components/ProfileBanner';
import Tabs from '../components/Tabs';

interface CollectedPageProps {
    account: IAccount
    activeTab?: string
}

const FavoritesPage: React.FC<CollectedPageProps> = ( props ) => {
    const { activeTab, account } = props;

    return (
        <>
            <PageHeader/>
            <Page>
                <ProfileBanner account={ account }/>
                <ProfileHeader account={ account }/>
                <Tabs walletAddress={ account.walletAddress } activeTab={ activeTab }/>
                <PaddedRow>
                    <Text color="textSubtle" textAlign="center" pt={ 40 }>
                        No favorited NFTs
                    </Text>
                </PaddedRow>
            </Page>
        </>
    );
};

export default FavoritesPage;