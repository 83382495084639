import styled from 'styled-components';
import { CheckboxProps, scales } from './types';

const getScale = ( { scale }: CheckboxProps ) => {
    switch ( scale ) {
        case scales.SM:
            return '18px';
        case scales.MD:
        default:
            return '24px';
    }
};

const Checkbox = styled.input.attrs( { type: 'checkbox' } )<CheckboxProps>`
  appearance: none;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  display: inline-block;
  min-height: ${ getScale };
  height: ${ getScale };
  min-width: ${ getScale };
  width: ${ getScale };
  vertical-align: middle;
  transition: background-color 0.2s ease-in-out;
  border: 1px solid ${ ( { theme } ) => theme.colors.inputBorder };
  border-radius: 8px;
  background-color: ${ ( { theme } ) => theme.colors.inputBackground };
  box-shadow: ${ ( { theme } ) => theme.shadows.inset };

  &:after {
    content: "";
    position: absolute;
    border-bottom: 2px solid;
    border-left: 2px solid;
    border-color: transparent;
    top: 30%;
    left: 0;
    right: 0;
    width: 50%;
    height: 25%;
    margin: auto;
    transform: rotate(-50deg);
    transition: border-color 0.2s ease-in-out;
  }

  &:focus {
    outline: none;
  }

  &:checked {
    border: 0px none;
    background-color: ${ ( { theme } ) => theme.colors.primary };
    &:after {
      border-color: white;
    }
  }

  &:disabled {
    cursor: default;
    opacity: 0.6;
  }
`;

Checkbox.defaultProps = {
    scale: scales.MD,
};

export default Checkbox;
