import React from 'react';
import { AccordionSection, Skeleton, TagIcon } from 'components';
import SpacedBetweenRow from './SpacedBetweenRow';
import { FetchStatus, ICollection } from 'config/constants/types';

export interface CollectionPropertiesSectionProps {
    collectionData?: ICollection
    collectionDataFetchStatus: FetchStatus
}

const CollectionPropertiesSection: React.FC<CollectionPropertiesSectionProps> = ( props ) => {
    const { collectionData, collectionDataFetchStatus } = props;

    return (
        <AccordionSection label="Properties" icon={ <TagIcon/> }>
            <SpacedBetweenRow>
                <span>Symbol:</span>
                { collectionDataFetchStatus === FetchStatus.PENDING ? (
                    <Skeleton width="10rem"/>
                ) : (
                    <span>{ collectionData?.symbol || 'Not available' }</span>
                ) }
            </SpacedBetweenRow>

            <SpacedBetweenRow>
                <span>IP Rights:</span>
                { collectionDataFetchStatus === FetchStatus.PENDING ? (
                    <Skeleton width="10rem"/>
                ) : (
                    <span>Not available</span>
                ) }
            </SpacedBetweenRow>

            <SpacedBetweenRow>
                <span>Collection:</span>
                { collectionDataFetchStatus === FetchStatus.PENDING ? (
                    <Skeleton width="10rem"/>
                ) : (
                    <span>{ collectionData?.title }</span>
                ) }
            </SpacedBetweenRow>
        </AccordionSection>
    );
};

export default CollectionPropertiesSection;