import React, { useRef } from 'react';
import { ModalBody, ModalCloseButton, ModalContainer, ModalHeader, ModalTitle } from 'components/Modal';
import { Button, Flex, Input, InputLabel, LoadingIcon, Select, Text } from 'components';
import styled from 'styled-components';
import { toast } from 'react-hot-toast';
import { formatNumber } from 'utils/formatBalance';
import useTokenBalance from 'hooks/useTokenBalance';
import tokens from 'config/constants/tokens';
import { FetchStatus, Token } from 'config/constants/types';

interface PlaceOfferModalProps {
    onDismiss?: () => void
}

const StyledModalBody = styled( ModalBody )`
    background: ${ ( { theme } ) => theme.modal.lightBackground };
`;

const PriceWrapper = styled.div`
    & select {
        margin-right: 1rem;
    }
`;

const ExpirationWrapper = styled.div`
    width: 100%;

    & > input {
        flex: 1;
        
        &:first-of-type {
            margin-right: 1rem;
        }
    }
`;

const BalanceWrapper = styled( Flex )`
    justify-content: flex-end;
    font-size: 0.5rem;
`;

const PlaceOfferModal: React.FC<PlaceOfferModalProps> = ( props ) => {
    const { onDismiss } = props;
    const {
        fetchTokenBalance,
        balance,
        fetchStatus: balanceFetchStatus
    } = useTokenBalance();

    const tokenInputRef = useRef<HTMLSelectElement>( null );

    const [ selectedCurrencyAddress, setSelectedCurrencyAddress ] = React.useState( '' );
    const quoteTokens = [
        tokens.ech,
        tokens.usdc,
    ];

    const handleTokenChanged = async () => {
        const tokenAddress = tokenInputRef?.current.value as string;
        setSelectedCurrencyAddress( tokenAddress );

        if ( tokenAddress ) {
            await fetchTokenBalance( tokenAddress );
        }
    };

    const handlePlaceOfferClicked = async () => {
        toast.error( 'Implementation pending on contract.' );
        return onDismiss();
    };

    return (
        <ModalContainer minWidth="320px">
            <ModalHeader>
                <ModalTitle>
                    <h2>Place your offer</h2>
                </ModalTitle>
                <ModalCloseButton onDismiss={ onDismiss }/>
            </ModalHeader>
            <StyledModalBody>
                <PriceWrapper>
                    <InputLabel label="Price" direction='vertical'>
                        <Flex>
                            <Select
                                ref={ tokenInputRef }
                                onChange={ handleTokenChanged }
                            >
                                <option value="">Select currency</option>
                                { quoteTokens.map( ( token: Token ) => (
                                    <option key={ token.address } value={ token.address }>{ token.name }</option>
                                ) ) }
                            </Select>
                            <Input type="number" disabled={ !selectedCurrencyAddress }/>
                        </Flex>
                    </InputLabel>
                </PriceWrapper>

                { selectedCurrencyAddress && (
                    <BalanceWrapper>
                        <Text small mr={ 1 }>Balance:</Text>
                        { balanceFetchStatus === FetchStatus.PENDING && <LoadingIcon/> }
                        { balanceFetchStatus === FetchStatus.FAILED && <Text small>Failed fetching</Text> }
                        { balanceFetchStatus === FetchStatus.SUCCESS && (
                            <Text small>{ formatNumber( balance.toNumber() ) }</Text>
                        ) }
                    </BalanceWrapper>
                ) }

                <InputLabel label="Offer Expiration" direction='vertical' mt={ 10 }>
                    <ExpirationWrapper>
                        <Input type='date' mr={ 1 }/>
                        <Input type='time'/>
                    </ExpirationWrapper>
                </InputLabel>

                <Flex justifyContent="center">
                    <Button mt={ 30 } onClick={ handlePlaceOfferClicked }>
                        Place Offer
                    </Button>
                </Flex>
            </StyledModalBody>
        </ModalContainer>
    );
};

export default PlaceOfferModal;
