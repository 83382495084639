import React from 'react';
import styled from 'styled-components';
import { connectorLocalStorageKey, walletLocalStorageKey } from './config';
import { Config, ConnectorNames, Login } from './types';
import { Button } from 'components/Button';

interface Props {
    walletConfig: Config;
    login: Login;
    onDismiss: () => void;
}

const WalletButton = styled( Button ).attrs( { width: '100%', variant: 'text', py: '16px' } )`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  color: #FFF;
  border-bottom: 1px solid ${ ( { theme } ) => theme.colors.primary }33;
  
  &:last-child {
    border-bottom: 0px none;
  }
`;

const WalletCard: React.FC<Props> = ( { login, walletConfig, onDismiss } ) => {
    const { title, icon: Icon } = walletConfig;

    return (
        <WalletButton
            variant="tertiary"
            onClick={ () => {
                const isIOS = /iPad|iPhone|iPod/.test( navigator.userAgent ) && !window.MSStream;

                // Since iOS does not support Trust Wallet we fall back to WalletConnect
                if ( walletConfig.title === 'Trust Wallet' && isIOS ) {
                    login( ConnectorNames.WalletConnect );
                } else {
                    login( walletConfig.connectorId );
                }

                localStorage.setItem( walletLocalStorageKey, walletConfig.title );
                localStorage.setItem( connectorLocalStorageKey, walletConfig.connectorId );

                onDismiss();
            } }
            id={ `wallet-connect-${ title.toLocaleLowerCase() }` }
        >
            <Icon width="40px" mb="8px"/>
            <div>{ title }</div>
        </WalletButton>
    );
};

export default WalletCard;
