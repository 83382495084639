import React from 'react';
import { ModalBody, ModalCloseButton, ModalContainer, ModalHeader, ModalTitle } from 'components/Modal';
import WalletCard from './WalletCard';
import config, { walletLocalStorageKey } from './config';
import { Config, Login } from './types';

interface Props {
    login: Login;
    onDismiss?: () => void;
    displayCount?: number;
}

/**
 * Checks local storage if we have saved the last wallet the user connected with
 * If we find something we put it at the top of the list
 *
 * @returns sorted config
 */
const getPreferredConfig = ( walletConfig: Config[] ) => {
    const preferredWalletName = localStorage.getItem( walletLocalStorageKey );
    const sortedConfig = walletConfig.sort( ( a: Config, b: Config ) => a.priority - b.priority );

    if ( !preferredWalletName ) {
        return sortedConfig;
    }

    const preferredWallet = sortedConfig.find( ( sortedWalletConfig ) => sortedWalletConfig.title === preferredWalletName );

    if ( !preferredWallet ) {
        return sortedConfig;
    }

    return [
        preferredWallet,
        ...sortedConfig.filter( ( sortedWalletConfig ) => sortedWalletConfig.title !== preferredWalletName ),
    ];
};

const ConnectModal: React.FC<Props> = ( { login, onDismiss = () => null, displayCount = 3 } ) => {
    const sortedConfig = getPreferredConfig( config );
    const displayListConfig = sortedConfig.slice( 0, displayCount );

    return (
        <ModalContainer minWidth="320px">
            <ModalHeader>
                <ModalTitle>
                    <h2>Connect Wallet</h2>
                </ModalTitle>
                <ModalCloseButton onDismiss={ onDismiss }/>
            </ModalHeader>
            <ModalBody>
                <div>
                    { displayListConfig.map( ( wallet ) => (
                        <WalletCard key={ wallet.title } walletConfig={ wallet } login={ login } onDismiss={ onDismiss }/>
                    ) ) }
                </div>
            </ModalBody>
        </ModalContainer>
    );
};

export default ConnectModal;
