import { DefaultTheme } from 'styled-components';
import { modalTheme } from 'components/Modal/theme';
import { ModalTheme } from 'components/Modal/types';

import { Breakpoints, Colors, MediaQueries, Shadows, Spacing, Tooltip, ZIndices } from './types';

import base from './base';
import { appColors } from './colors';
import { shadows } from './shadows';
import { tooltip } from './tooltip';

export interface AppTheme {
    siteWidth: number;
    isDark: boolean;
    colors: Colors;
    modal: ModalTheme;
    breakpoints: Breakpoints;
    mediaQueries: MediaQueries;
    shadows: Shadows;
    spacing: Spacing;
    tooltip: Tooltip;
    zIndices: ZIndices;
}

export const appTheme: DefaultTheme = {
    ...base,
    isDark: false,
    colors: appColors,
    shadows: shadows,
    tooltip: tooltip,
    modal: modalTheme,
};