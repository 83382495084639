import React, { useState } from 'react';
import { LoadingIcon } from 'components';
import styled from 'styled-components';
import { FetchStatus } from '../../config/constants/types';
import FALLBACK_IMAGE from 'assets/images/image-not-found.png';

interface ImageProps {
    src: string // required
    alt: string // required
}

const ImageWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const LoadingOverlay = styled.div`
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Image: React.FC<ImageProps> = ( props ) => {
    const { src, alt } = props;

    const [ fetchStatus, setFetchStatus ] = useState<FetchStatus>( FetchStatus.PENDING );

    const handleFinishLoading = ( _event: React.SyntheticEvent<HTMLImageElement, Event> ) => {
        setFetchStatus( FetchStatus.SUCCESS );
    };

    const handleErrorLoading = ( event: React.SyntheticEvent<HTMLImageElement, Event> ) => {
        setFetchStatus( FetchStatus.FAILED );
        event.currentTarget.src = FALLBACK_IMAGE;
    };

    return (
        <ImageWrapper>
            { fetchStatus === FetchStatus.PENDING && (
                <LoadingOverlay>
                    <LoadingIcon color='black'/>
                </LoadingOverlay>
            ) }
            <img
                src={ src }
                alt={ alt }
                style={ {
                    display: fetchStatus !== FetchStatus.SUCCESS ? 'none' : '',
                    width: '100%',
                } }
                onLoad={ handleFinishLoading }
                onError={ handleErrorLoading }
            />
        </ImageWrapper>
    );
};

export default Image;