import React from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Flex, PaddedRow, Page, PageHeader } from 'components';
import CollectionBannerLogo from './components/CollectionBannerLogo';
import CollectionHeader from './components/CollectionHeader';
import CollectionNumbers from './components/CollectionNumbers';
import CollectionFilters from './components/CollectionFilters';
import CollectionItems from './components/CollectionItems';
import SearchBar from './components/SearchBar';
import useCollectionData from './hooks/useCollectionData';
import { COLLECTION_BASE_URL } from './types';

const TabButton = styled( Link )`
    padding: 0.75rem 0;
    margin-right: 1.2rem;
`;

const InactiveTabButton = styled( TabButton )`
    color: black;
`;

const ActiveTabButton = styled( TabButton )`
    border-bottom: 4px solid black;
`;

const Collection: React.FC = () => {
    const { slug } = useParams<{ slug: string }>();
    const { collectionData, fetchStatus: dataFetchStatus } = useCollectionData( slug );

    const [ filtersVisible, setFiltersVisible ] = React.useState( true );

    return (
        <>
            <PageHeader/>
            <Page>
                <CollectionBannerLogo
                    dataFetchStatus={ dataFetchStatus }
                    collectionData={ collectionData }
                />
                <CollectionHeader
                    dataFetchStatus={ dataFetchStatus }
                    collectionData={ collectionData }
                />
                <CollectionNumbers
                    dataFetchStatus={ dataFetchStatus }
                    collectionData={ collectionData }
                />

                <PaddedRow my={ 2 }>
                    <Flex alignItems="flex-start" justifyContent="flex-start">
                        <ActiveTabButton to={ `${ COLLECTION_BASE_URL }/${ slug }/items` }>Items</ActiveTabButton>
                        <InactiveTabButton to={ `${ COLLECTION_BASE_URL }/${ slug }/activity` }>Activity</InactiveTabButton>
                    </Flex>
                </PaddedRow>

                <SearchBar
                    filtersVisible={ filtersVisible }
                    setFiltersVisible={ setFiltersVisible }
                />

                <PaddedRow>
                    <Flex alignItems="flex-start" justifyContent="flex-start">
                        { filtersVisible && (
                            <CollectionFilters
                                mr={ 4 }
                                collection={ collectionData }
                            />
                        ) }
                        <CollectionItems collectionSlug={ slug }/>
                    </Flex>
                </PaddedRow>
            </Page>
        </>
    );
};

export default Collection;
