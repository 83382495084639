import React from 'react';
import styled from 'styled-components';
import { Flex } from '../Box';
import { ChevronIcon } from '../Svg';
import { Text } from '../Text';
import { space, SpaceProps } from 'styled-system';

export interface AccordionSectionProps extends SpaceProps {
    label: string
    secondaryLabel?: string
    icon?: React.ReactNode;
    expanded?: boolean
    onOpen?: () => void
    onClose?: () => void
}

const TitleWrapper = styled( Flex )<{ isExpanded?: boolean }>`
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    ${ ( { isExpanded } ) => isExpanded && `
        background-color: #F3F5F8;
    ` };
    
    &:hover {
        background-color: #F3F5F8;
    }
    
    ${ space }
`;

const BodyWrapper = styled.div`
    background-color: #F3F5F8;
    padding: 16px;
`;

const StyledChevronIcon = styled( ChevronIcon )<{ expanded: boolean }>`
    height: 16px;
    transform: rotate(${ ( { expanded } ) => expanded ? '270deg' : '180deg' });
    transition: all 0.3s;
`;

const AccordionSection: React.FC<AccordionSectionProps> = ( props ) => {
    const { label, secondaryLabel, icon, expanded, children, onOpen, onClose, ...otherProps } = props;

    const [ isExpanded, setExpanded ] = React.useState( expanded || false );

    return (
        <>
            <TitleWrapper
                p={ 3 }
                isExpanded={ isExpanded }
                onClick={ () => {
                    if ( isExpanded ) {
                        if ( onClose ) onClose();
                    } else {
                        if ( onOpen ) onOpen();
                    }

                    setExpanded( !isExpanded );
                } }
            >
                <Flex alignItems="center" justifyContent="flex-start">
                    { React.isValidElement( icon ) && React.cloneElement( icon, { mr: '0.5em' } ) }
                    <Text bold>{ label }</Text>
                </Flex>

                <Flex alignItems="center" justifyContent="flex-end">
                    { secondaryLabel && (
                        <Text color="textSubtle">{ secondaryLabel }</Text>
                    ) }
                    <StyledChevronIcon expanded={ isExpanded }/>
                </Flex>
            </TitleWrapper>
            { isExpanded && (
                <BodyWrapper { ...otherProps }>
                    { children }
                </BodyWrapper>
            ) }
        </>
    );
};

export default AccordionSection;