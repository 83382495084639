import React from 'react';
import { Accordion, AccordionSection, Flex, GridLayout, PanelIcon } from 'components';
import { FetchStatus, IAsset } from 'config/constants/types';
import CollectionAsset from 'views/Collection/components/CollectionAsset';
import CollectionAssetSkeleton from 'views/Collection/components/CollectionAssetSkeleton';
import useMoreFromCollection from '../hooks/useMoreFromCollection';

interface MoreFromCollectionProps {
    collectionSlug: string,
    assetId: string
}

const MoreFromCollection: React.FC<MoreFromCollectionProps> = ( props ) => {
    const { collectionSlug, assetId } = props;

    const { assets, fetchAssets, fetchStatus } = useMoreFromCollection( collectionSlug, assetId, 5 );

    return (
        <Accordion my={ 4 }>
            <AccordionSection
                label="More from this collection"
                icon={ <PanelIcon/> }
                onOpen={ () => assets.length === 0 && fetchAssets() }
            >
                { fetchStatus === FetchStatus.PENDING ? (
                    <GridLayout>
                        { [ 1, 2, 3, 4, 5 ].map( ( arg ) => <CollectionAssetSkeleton key={ arg }/> ) }
                    </GridLayout>
                ) : (
                    <Flex>
                        { assets?.map( ( collectionItem: IAsset ) => (
                            <CollectionAsset
                                key={ collectionItem.title }
                                asset={ collectionItem }
                            />
                        ) ) }
                    </Flex>
                ) }
            </AccordionSection>
        </Accordion>
    );
};

export default MoreFromCollection;